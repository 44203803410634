import React, { useEffect, useState } from "react";
import axios from "axios";
import "react-responsive-modal/styles.css";
import Loader from "./loader.svg";
import "./loader.css";
import { Modal } from "reactstrap";
import { url } from "./helper";
import CustomTable from "./CustomTable";
import Cart from "./Cart";
import Invoice from "./Invoice";

const Order = (props) => {
  const userId = localStorage.getItem("businessid");
  const [customerData, setCustomerData] = useState([]);
  const [businessrData, setBusinessData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [editUser, setEditUser] = useState(false);
  const [editUserById, setEditUserById] = useState({});
  const [orderId, setOrderId] = useState("");

  const url2 = `${url}/api/order`;
  useEffect(async () => {
    getApiMathod();
  }, []);

  const getApiMathod = async () => {
    if (localStorage.getItem("businessid") !== null) {
      await axios.get(url2).then((response) => {
        setCustomerData(
          response.data.data.filter((i) => (i.businessId === userId) & (i.cart === true)).reverse(),
        );
      });
    }
  };

  useEffect(async () => {
    getAllBusiness();
  }, []);

  const url3 = `${url}/api/business`;
  const getAllBusiness = async () => {
    await axios.get(url3).then((response) => {
      setBusinessData(response.data.data);
    });
  };
  useEffect(async () => {
    getAllUsers();
  }, []);
  const url4 = `${url}/api/newuser`;
  const getAllUsers = async () => {
    await axios.get(url4).then((response) => {
      setUserData(response.data.data);
    });
  };

  const userEditHandeler = async (userid) => {
    setOrderId(userid);
    setShowOrderDetail(true);
  };
  const userInvoiceHandeler = async (userid) => {
    setOrderId(userid);
    setShowInvoice(true);
  };

  const SubmitUserEditHandeler = async (userid) => {
    await axios.put(`${url}/api/order/${userid}`, editUserById).then((response) => {
      getApiMathod();
    });
    setEditUser(false);
    setOrderId("");
  };

  const closeCart = () => {
    setOrderId("");
    setShowOrderDetail(false);
  };

  const closeInvoice = () => {
    setOrderId("");
    setShowInvoice(false);
  };

  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);

  const SubmitUserDeleteHandeler = async (userid) => {
    await axios.delete(`${url}/api/order/${userid}`);
  };

  const onCloseModal = () => {
    setEditUser(false);
  };

  const businessName = (x) => {
    const user = userData.filter((i) => i._id === x).map((item) => item.firstName)[0];
    const business = businessrData.filter((i) => i._id === x).map((item) => item.name)[0];
    return user === "" ? business : user;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "All Order Details",
        columns: [
          {
            Header: "Order. NO.",
            accessor: "",
          },
          {
            Header: "Order Date",
            accessor: "date",
          },
          {
            Header: "Order For",
            accessor: "orderFor",
          },
          {
            Header: "Total Amount",
            accessor: "totalPrice",
          },
          {
            Header: "Status",
            accessor: "",
          },
          {
            Header: "Action",
            accessor: "_id",
          },
        ],
      },
    ],
    [],
  );
  const data = customerData;
  return (
    <>
      {showOrderDetail ? (
        <Cart
          orderId={orderId}
          SubmitUserEditHandeler={SubmitUserEditHandeler}
          closeCart={closeCart}
          SubmitUserDeleteHandeler={SubmitUserDeleteHandeler}
        />
      ) : showInvoice ? (
        <Invoice orderId={orderId} closeInvoice={closeInvoice} />
      ) : (
        <>
          {!customerData ? (
            <div className="loaderDiv">
              <img src={Loader} alt="Loading" />
            </div>
          ) : (
            <div style={{ margin: "5%" }}>
              <CustomTable
                columns={columns}
                data={data}
                userEditHandeler={userEditHandeler}
                userInvoiceHandeler={userInvoiceHandeler}
              />
            </div>
          )}

          <Modal isOpen={editUser} toggle={onCloseModal} classNames="popupModalRegisterUser">
            <div style={{ margin: "5%" }}>
              <div>Details of Order</div>
              <div className="fuleListModalChild">
                <div>
                  <span>Order For</span>
                  <input type="text" value={editUserById.orderFor} style={{ border: "none" }} />
                </div>
                <div>
                  <span>Order From</span>
                  <input
                    type="text"
                    value={businessName(editUserById.businessId)}
                    style={{ border: "none" }}
                  />
                </div>
                <div>
                  <span>Order Date</span>
                  <input type="text" value={editUserById.date} style={{ border: "none" }} />
                </div>
                <div>
                  <span>Size</span>
                  <input type="text" value={editUserById.size} style={{ border: "none" }} />
                </div>
                <div>
                  <span>Paper Type</span>
                  <input type="text" value={editUserById.paperType} style={{ border: "none" }} />
                </div>
                <div>
                  <span>GSM</span>
                  <input
                    type="text"
                    name="name"
                    value={editUserById.gsm}
                    style={{ border: "none" }}
                  />
                </div>
                <div>
                  <span>Number Of Colors</span>
                  <input
                    type="text"
                    value={editUserById.numberOfcolors}
                    style={{ border: "none" }}
                  />
                </div>
                <div>
                  <span>Print Side</span>
                  <input type="text" value={editUserById.printSide} style={{ border: "none" }} />
                </div>
                {editUserById.LaminationFabricFinishing === true ? (
                  <>
                    <div>
                      <span>Lamination Type</span>
                      <input
                        type="text"
                        value={editUserById.LaminationType}
                        style={{ border: "none" }}
                      />
                    </div>
                    <div>
                      <span>Lamination Side</span>
                      <input
                        type="text"
                        value={editUserById.LaminationSide}
                        style={{ border: "none" }}
                      />
                    </div>
                  </>
                ) : null}
                <div>
                  <span>Paper Price</span>
                  <input type="text" value={editUserById.paperPrice} style={{ border: "none" }} />
                </div>
                <div>
                  <span>Printing Price</span>
                  <input
                    type="text"
                    value={editUserById.printingPrice}
                    style={{ border: "none" }}
                  />
                </div>
                {editUserById.LaminationFabricFinishing === true ? (
                  <div>
                    <span>Lamination Price</span>
                    <input
                      type="text"
                      value={editUserById.laminationPrice}
                      style={{ border: "none" }}
                    />
                  </div>
                ) : null}
                <div>
                  <span>Total Price</span>
                  <input type="text" value={editUserById.totalPrice} style={{ border: "none" }} />
                </div>
              </div>
              <div>
                {!editUserById.status ? (
                  <button
                    style={{
                      marginLeft: "20%",
                      border: "none",
                      width: "20%",
                      background: "burlywood",
                      borderRadius: 10,
                      color: "white",
                    }}
                    onClick={() => SubmitUserEditHandeler(editUserById._id)}
                  >
                    Accept
                  </button>
                ) : null}
                <button
                  style={{
                    marginLeft: "20%",
                    border: "none",
                    width: "20%",
                    background: "burlywood",
                    borderRadius: 10,
                    color: "white",
                  }}
                  onClick={() => onCloseModal()}
                >
                  Close
                </button>{" "}
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default Order;
