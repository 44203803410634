import React, { useState, useEffect } from "react";
import axios from "axios";
import { url } from "./helper";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import img1 from "./Images/calandar/calendar01.jpg";
import img2 from "./Images/calandar/calendar02.jpg";
import img3 from "./Images/calandar/calendar03.jpg";
import img4 from "./Images/calandar/calendar04.jpg";
import Cart from "./Cart";
import { Modal, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import { changeOrders } from "../actions/action";
import Login from "./Login1";

const Calendar = (props) => {
  const goToHome = () => {
    props.history.push("");
  };
  const [showLogin, setShowLogin] = useState(false);
  const loginoff = () => {
    setShowLogin(false);
    setEditUser(false);
  };
  const token = localStorage.getItem("token");
  const isLoggedIn = token ? true : false;
  const [editUser, setEditUser] = useState(false);
  const businessId = localStorage.getItem("businessid");
  const [updateOrder, setUpdateOrder] = useState({
    businessId: businessId,
    orderFor: "Calendar",
    calendarType: "",
    design1: "",
    design2: "",
    cdr: "",
    size: "",
    bookInnerGsm: "",
    bookInnerPaperType: "",
    bookInnerNumberOfColors: "",
    NumberofLeaf: "",
    printSide: "",
    Quantity: "",
    LaminationFabricFinishing: false,
    LaminationType: "",
    LaminationSide: "",
    binding: "",
  });
  const [showDetails, setShowDetails] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const setForm = (event) => {
    let { name, value } = event.target;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    setUpdateOrder({
      ...updateOrder,
      [name]: value,
    });
  };
  const setFormp = (event) => {
    let { name, value } = event.target;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    if (24 > value) {
      setUpdateOrder({
        ...updateOrder,
        [name]: value,
      });
    } else {
      setUpdateOrder({
        ...updateOrder,
        [name]: 24,
      });
      alert("Maximum Limit is 24");
    }
  };
  const setFormpt = (event) => {
    let { name, value } = event.target;
    const paperName = value.split("(")[0];
    setUpdateOrder({
      ...updateOrder,
      [name]: paperName,
    });
  };
  const SubmitOrderHandler = async () => {
    const totalPrice = newTotalCost();
    const newOrder = {
      ...updateOrder,
      totalPrice: totalPrice,
    };
    if (isLoggedIn) {
      await axios
        .post(`${url}/api/order`, newOrder)
        .then((res) => props.changeorders([...props.orders, res.data.data]))
        .then(() => {
          alert("New Order Added To Cart");
          setShowCart(false);
        });
    } else {
      localStorage.setItem("currentpage", "Calendar");
      setEditUser(true);
    }
  };
  const showDetailsHandler = () => {
    if (updateOrder.size === "") {
      alert("Please Select Calendar size");
    } else if (updateOrder.Quantity === "") {
      alert("enter diary quantity");
    } else if (updateOrder.printSide === "") {
      alert("select print side for cover");
    } else if (updateOrder.bookInnerNumberOfPages === "") {
      alert("enter number of pages for inner field");
    } else if (updateOrder.bookInnerPaperType === "") {
      alert("select inner paper type");
    } else if (updateOrder.bookInnerGsm === "") {
      alert("select inner gsm");
    } else if (updateOrder.bookInnerNumberOfColors === "") {
      alert("select number of colors for inner");
    } else if (updateOrder.binding === "") {
      alert("select binding");
    } else if (
      (updateOrder.LaminationType === "") &
      (updateOrder.LaminationFabricFinishing === true)
    ) {
      alert("select lamination type");
    } else {
      setShowDetails(true);
    }
  };
  const onCloseModal = () => {
    setEditUser(false);
  };
  const [staticsizes, setStaticsizes] = useState([]);
  useEffect(() => {
    getApiMathod7();
  }, []);
  const url7 = `${url}/api/sizes`;
  const getApiMathod7 = async () => {
    try {
      const response = await axios.get(url7);
      setStaticsizes(response.data.data);
    } catch (error) {
      var err2 = error.response;
      console.log(err2);
    }
  };

  const [gsmData, setGsmData] = useState([]);
  useEffect(() => {
    getGsmData();
  }, []);
  const url9 = `${url}/api/gsm`;
  const getGsmData = async () => {
    try {
      const response = await axios.get(url9);
      setGsmData(response.data.data);
    } catch (error) {
      var err2 = error.response;
      console.log(err2);
    }
  };

  const printsideValue = [
    { name: "All Single Side", q1: 1, q2: 0 },
    { name: "All Double Side", q1: 2, q2: 0 },
    { name: "Cover Single Inner Double Side", q1: 2, q2: 1 },
  ];

  const laminationsideValue = [
    { name: "ALL Single Side", q1: 1, q2: 1 },
    { name: "ALL Double Side", q1: 2, q2: 1 },
    { name: "Only Cover Lamination", q1: 1, q2: 24 },
  ];

  const gsmI = gsmData
    .filter((item) => item.paperName === updateOrder.bookInnerPaperType)
    .map((i) => i.gsm);

  const [bindingPrice, setBindingPrice] = useState([]);
  const [paperPrice, setPaperPrice] = useState([]);
  const [colorPrice, setColorPrice] = useState([]);

  useEffect(() => {
    getApiMathod1();
  }, []);
  const url2 = `${url}/api/paperprice`;
  const getApiMathod1 = async () => {
    try {
      const response = await axios.get(url2);
      setPaperPrice(response.data.data.filter((item) => item.forCalendarPrinting === true));
      setCalendarTypes(response.data.data.filter((item) => item.forExtraCalendarType === true));
    } catch (error) {
      var err2 = error.response;
      console.log(err2);
    }
  };

  useEffect(() => {
    getApiMathod3();
  }, []);
  const url4 = `${url}/api/bindingprice`;
  const getApiMathod3 = async () => {
    try {
      const response = await axios.get(url4);

      setBindingPrice(response.data.data.filter((item) => item.forCalender === true));
    } catch (error) {
      var err2 = error.response;
      console.log(err2);
    }
  };
  const [laminationPrice, setLaminationPrice] = useState([]);

  useEffect(() => {
    getApiMathod4();
  }, []);
  const url5 = `${url}/api/laminationprice`;
  const getApiMathod4 = async () => {
    try {
      const response = await axios.get(url5);

      setLaminationPrice(response.data.data);
    } catch (error) {
      var err2 = error.response;
      console.log(err2);
    }
  };

  useEffect(() => {
    getApiMathod2();
  }, []);
  const url3 = `${url}/api/colorprice`;
  const getApiMathod2 = async () => {
    try {
      const response = await axios.get(url3);

      setColorPrice(response.data.data);
    } catch (error) {
      var err2 = error.response;
      console.log(err2);
    }
  };
  const sizes = staticsizes.filter((item) => item.paperName === updateOrder.calendarType);

  const NewPaperCost = () => {
    const selectedPaperType = paperPrice.filter(
      (i) => i.name === updateOrder.bookInnerPaperType,
    )[0];
    const selectedSize = sizes.filter((item) => item.name === updateOrder.size)[0];
    const coverSheet = (updateOrder.Quantity / selectedSize.q) * updateOrder.NumberofLeaf;
    const NewPaperCost =
      ((selectedSize.l * selectedSize.b * updateOrder.bookInnerGsm) / 1550000) *
      selectedPaperType.price *
      coverSheet;
    const totalPrice = Math.round(NewPaperCost);
    return totalPrice;
  };

  const NewPrintCost = () => {
    const selectedPrintside = printsideValue.filter((i) => i.name === updateOrder.printSide)[0];
    const selectedColor = colorPrice.filter(
      (i) => i.name === updateOrder.bookInnerNumberOfColors,
    )[0];
    const selectedSize = sizes.filter((item) => item.name === updateOrder.size)[0];
    const totalLeaf =
      (updateOrder.NumberofLeaf - selectedPrintside.q2) * selectedPrintside.q1 +
      selectedPrintside.q2;

    const setQuantity = Math.ceil(totalLeaf / (selectedSize.q / selectedSize.css));

    const printSheet =
      (updateOrder.Quantity * totalLeaf) / setQuantity / (selectedSize.q / selectedSize.css);

    const PrintCost =
      [Math.ceil(printSheet / 1000) * selectedColor.price + selectedColor.plate] * setQuantity;

    const totalPrice = Math.round(PrintCost);
    return totalPrice;
  };

  const newLaminationCost = () => {
    const selectedSize = sizes.filter((item) => item.name === updateOrder.size)[0];
    const selectedLamination = laminationPrice.filter(
      (i) => i.name === updateOrder.LaminationType,
    )[0];
    const selectedLaminationSide = laminationsideValue.filter(
      (i) => i.name === updateOrder.LaminationSide,
    )[0];
    const laminationCost =
      Math.ceil(updateOrder.NumberofLeaf / selectedLaminationSide.q2) * selectedLaminationSide.q1;

    const totalPrice = Math.round(
      laminationCost * updateOrder.Quantity * selectedSize.iq * selectedLamination.price,
    );
    return totalPrice;
  };

  const newBindingCost = () => {
    const selectedBinding = bindingPrice.filter((item) => item.name === updateOrder.binding)[0];
    const selectedSize = sizes.filter((item) => item.name === updateOrder.size)[0];
    const foldingForm = updateOrder.NumberofLeaf / (selectedSize.q / selectedSize.css);
    const bindingCost =
      selectedBinding.fixedCost * selectedSize.f +
      (foldingForm * selectedSize.bc + foldingForm * (selectedBinding.price * selectedSize.f));
    const totalPrice = Math.round(bindingCost * updateOrder.Quantity);

    return totalPrice;
  };

  const newTotalCost = () => {
    const totalCost =
      NewPaperCost() +
      NewPrintCost() +
      ((updateOrder.LaminationFabricFinishing === true) &
      (updateOrder.LaminationType !== "") &
      (updateOrder.LaminationSide !== "")
        ? newLaminationCost()
        : 0) +
      newBindingCost();
    return totalCost;
  };

  const [calendarTypes, setCalendarTypes] = useState([]);

  const PaperBrand = () => {
    if (paperPrice.filter((i) => i.name === updateOrder.bookInnerPaperType).length) {
      const selectedPaperType = paperPrice.filter(
        (i) => i.name === updateOrder.bookInnerPaperType,
      )[0];
      return `(${selectedPaperType.companyName})`;
    }
  };
  const PaperBrandInner = () => {
    if (paperPrice.filter((i) => i.name === updateOrder.bookInnerPaperType).length) {
      const selectedPaperType = paperPrice.filter(
        (i) => i.name === updateOrder.bookInnerPaperType,
      )[0];
      return `(${selectedPaperType.companyName})`;
    }
  };
  const PaperSays = () => {
    if (staticsizes.filter((item) => item.name === updateOrder.size).length) {
      const selectedSize = staticsizes.filter((item) => item.name === updateOrder.size)[0];
      if (selectedSize.says !== "") {
        return `(${selectedSize.says})`;
      }
    }
  };
  return (
    <>
      {showLogin ? (
        <Login loginoff={loginoff} />
      ) : (
        <>
          <div className="bg-white">
            <div className="container">
              <h2>CALENDAR PRINTING</h2>
              <div className="row mb-3">
                <div className="col-sm-6">
                  <div className="row">
                    <div
                      id="carouselExampleIndicators"
                      className="carousel slide w-100"
                      data-ride="carousel"
                    >
                      <div className="carousel-inner" style={{ paddingTop: "1rem" }}>
                        <CarouselProvider
                          naturalSlideWidth={100}
                          naturalSlideHeight={70}
                          totalSlides={4}
                        >
                          <Slider>
                            <Slide index={0}>
                              {" "}
                              <img src={img1} className="w-100" />
                            </Slide>
                            <Slide index={1}>
                              {" "}
                              <img src={img2} className="w-100" />
                            </Slide>
                            <Slide index={2}>
                              {" "}
                              <img src={img3} className="w-100" />
                            </Slide>
                            <Slide index={2}>
                              {" "}
                              <img src={img3} className="w-100" />
                            </Slide>
                          </Slider>
                          <ButtonNext className="carousel-control-next">
                            <i class="fa fa-arrow-right fa-2x" aria-hidden="true"></i>
                          </ButtonNext>
                          <ButtonBack className="carousel-control-prev">
                            <i class="fa fa-arrow-left fa-2x" aria-hidden="true"></i>
                          </ButtonBack>
                        </CarouselProvider>
                      </div>
                      <div className="row justify-content-center mt-2">
                        <div className="col-2  ">
                          <div
                            className="row"
                            data-target="#carouselExampleIndicators"
                            data-slide-to={0}
                          >
                            <img src={img1} className="img-fluid" />
                          </div>
                        </div>
                        <div className="col-2  ">
                          <div
                            className="row"
                            data-target="#carouselExampleIndicators"
                            data-slide-to={1}
                          >
                            <img src={img2} className="img-fluid" />
                          </div>
                        </div>
                        <div className="col-2 ">
                          <div
                            className="row"
                            data-target="#carouselExampleIndicators"
                            data-slide-to={2}
                          >
                            <img src={img3} className="img-fluid" />
                          </div>
                        </div>
                        <div className="col-2 ">
                          <div
                            className="row"
                            data-target="#carouselExampleIndicators"
                            data-slide-to={3}
                          >
                            <img src={img4} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="m-3 p-4 bg-white justify-content-center">
                    <div
                      className="p-0 m-0 text-center bg-light text-dark my-auto"
                      style={{ borderRadius: "10px" }}
                    >
                      <h4 className=" m-0 p-2">Calendar Calculation</h4>
                    </div>
                    <div>&nbsp;</div>
                    <form action="#">
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group row">
                            <label style={{ textAlign: "left" }}>Calender Type</label>

                            <select
                              className="form-control"
                              name="calendarType"
                              onChange={(e) => setForm(e)}
                            >
                              <option value="" disabled selected>
                                -Select Calender Type-
                              </option>
                              {calendarTypes.map((item) => (
                                <option>{item.name}</option>
                              ))}
                            </select>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label style={{ textAlign: "left" }}>No. of Leafs</label>
                            <input
                              placeholder="-Enter No Of Leafs-"
                              type="text"
                              className="form-control"
                              name="NumberofLeaf"
                              value={updateOrder.NumberofLeaf}
                              onChange={(e) => setFormp(e)}
                            />
                          </div>
                          {/* <h6
                        _ngcontent-yxf-c18=""
                        class="bg-info p-2 text-light text-center"
                        style={{ borderRadius: 20 }}
                      >
                        Cover Page Fields
                      </h6> */}

                          {/* <div className="form-group row">
                        <label style={{ textAlign: 'left' }}>Paper Type</label>

                        <select
                          className="form-control"
                          name="paperType"
                          onChange={(e) => setFormpt(e)}
                        >
                          <option value="" disabled selected>
                            -Select Paper Type-
                          </option>
                          {paperPrice.map((item) => (
                            <option>{item.name}({item.companyName})</option>
                          ))}
                        </select>
                      </div> */}
                          <div>&nbsp;</div>
                          {/* <div className="form-group row">
                        <label style={{ textAlign: 'left' }}>GSM *</label>

                        <select className="form-control" name="gsm" onChange={(e) => setForm(e)}>
                          <option value="" disabled selected>
                            -Select GSM-
                          </option>
                          {gsmC.map((item) => (
                            <option>{item}</option>
                          ))}
                        </select>
                      </div> */}
                          {/* <div>&nbsp;</div> */}
                          {/* <div className="form-group row">
                        <label style={{ textAlign: 'left' }}>No of Colors *</label>
                        <select
                          className="form-control"
                          name="numberOfcolors"
                          onChange={(e) => setForm(e)}
                        >
                          <option value="" disabled selected>
                            -Select No Of Colors-
                          </option>
                          <option>Single Color</option>
                          <option>Double Color</option>
                          <option>Four Color</option>
                        </select>
                      </div> */}
                          {/* <div>&nbsp;</div> */}

                          <div className="form-group row">
                            <label style={{ textAlign: "left" }}>Print Side *</label>
                            <select
                              className="form-control"
                              name="printSide"
                              onChange={(e) => setForm(e)}
                            >
                              <option value="" disabled selected>
                                -Select Print Side-
                              </option>
                              {printsideValue.map((item) => (
                                <option>{item.name}</option>
                              ))}
                            </select>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label style={{ textAlign: "left" }}>Quantity</label>
                            <input
                              placeholder="-Enter Quantity-"
                              type="text"
                              className="form-control"
                              name="Quantity"
                              onChange={(e) => setForm(e)}
                            />
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label style={{ textAlign: "left" }}>Binding *</label>

                            <select
                              className="form-control"
                              name="binding"
                              onChange={(e) => setForm(e)}
                            >
                              <option value="" disabled selected>
                                -Select Binding-
                              </option>
                              {bindingPrice.map((item) => (
                                <option>{item.name}</option>
                              ))}
                            </select>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group row">
                            <label style={{ textAlign: "left" }}>Size</label>

                            <select
                              className="form-control"
                              name="size"
                              onChange={(e) => setForm(e)}
                            >
                              <option value="" disabled selected>
                                -Select Size-
                              </option>
                              {sizes.map((item) => (
                                <option>{item.name}</option>
                              ))}
                            </select>
                          </div>
                          <div style={{ color: "rgb(30, 115, 253)" }}>&nbsp;{PaperSays()}</div>
                          {/* <h6
                        _ngcontent-yxf-c18=""
                        class="bg-info p-2 text-light text-center"
                        style={{ borderRadius: 20 }}
                      >
                        Inner Page Fields
                      </h6> */}

                          {/* <div>&nbsp;</div> */}
                          <div className="form-group row">
                            <label style={{ textAlign: "left" }}>Paper Type</label>

                            <select
                              className="form-control"
                              name="bookInnerPaperType"
                              onChange={(e) => setFormpt(e)}
                            >
                              <option value="" disabled selected>
                                -Select Paper Type-
                              </option>
                              {paperPrice.map((item) => (
                                <option>
                                  {item.name}({item.companyName})
                                </option>
                              ))}
                            </select>
                          </div>

                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label style={{ textAlign: "left" }}>GSM *</label>

                            <select
                              className="form-control"
                              name="bookInnerGsm"
                              onChange={(e) => setForm(e)}
                            >
                              <option value="" disabled selected>
                                -Select GSM-
                              </option>
                              {gsmI.map((item) => (
                                <option>{item}</option>
                              ))}
                            </select>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label style={{ textAlign: "left" }}>No of Colors *</label>
                            <select
                              className="form-control"
                              name="bookInnerNumberOfColors"
                              onChange={(e) => setForm(e)}
                            >
                              <option value="" disabled selected>
                                -Select No Of Colors-
                              </option>
                              <option>Single Color</option>
                              <option>Double Color</option>
                              <option>Four Color</option>
                            </select>
                          </div>

                          <div>&nbsp;</div>
                        </div>
                        <div className="col-xl-6">
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <input
                              type="checkbox"
                              class="form-check-input"
                              id="exampleCheck1"
                              name="LaminationFabricFinishing"
                              onChange={(e) => setForm(e)}
                            />
                            <label style={{ textAlign: "left", marginLeft: "5%" }}>
                              Lamination & Finishing
                            </label>
                          </div>
                          {updateOrder.LaminationFabricFinishing === true ? (
                            <div>
                              <div>
                                <div className="form-group row">
                                  <label style={{ textAlign: "left" }}>Lamination</label>

                                  <select
                                    className="form-control"
                                    name="LaminationType"
                                    onChange={(e) => setForm(e)}
                                  >
                                    <option value="" disabled selected>
                                      -Select Lamination-
                                    </option>
                                    {laminationPrice.map((item) => (
                                      <option>{item.name}</option>
                                    ))}
                                  </select>
                                </div>
                                <div>&nbsp;</div>
                              </div>
                              <div>
                                <div className="form-group row">
                                  <label style={{ textAlign: "left" }}>Lamination Side*</label>

                                  <select
                                    className="form-control"
                                    name="LaminationSide"
                                    onChange={(e) => setForm(e)}
                                  >
                                    <option value="" disabled selected>
                                      -Select Lamination Side-
                                    </option>
                                    {laminationsideValue.map((item) => (
                                      <option>{item.name}</option>
                                    ))}
                                  </select>
                                </div>
                                <div>&nbsp;</div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="text-center mt-3">
                          <button
                            type="button"
                            className="btn btn-primary btn-lg"
                            style={{
                              paddingLeft: "5.5rem",
                              paddingRight: "5.5rem",
                            }}
                            onClick={() => showDetailsHandler()}
                          >
                            Calculate Price
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showDetails === true ? (
            <div className="container" style={{ paddingBottom: "2%" }}>
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{
                        backgroundColor: "rgb(6,120,139)",
                        color: "white",
                      }}
                    >
                      <p className="lead fw-bold ">Celendar Printing</p>
                      <div className="row">
                        <div className="col-sm-8">Specification</div>
                        <div className="col-sm-4" align="center">
                          Quantity
                        </div>
                      </div>
                    </div>
                    <div className="card-body" style={{ backgroundColor: "azure" }} mat-typography>
                      <div className="row">
                        <div className="col-sm-8">
                          {/* <h4 className="card-title"> Brochure</h4> */}
                          <p style={{ textAlign: "left" }}>
                            <b> Celendar Size</b> : {updateOrder.size}
                          </p>
                          <p style={{ textAlign: "left" }}>
                            <b> Paper </b>: {updateOrder.bookInnerGsm} gsm on{" "}
                            {updateOrder.bookInnerPaperType} paper with{" "}
                            {updateOrder.bookInnerNumberOfColors} <br /> {updateOrder.printSide}{" "}
                            printing
                          </p>

                          <p style={{ textAlign: "left" }}>
                            <b>Binding </b>: {updateOrder.binding} Binding
                          </p>

                          {updateOrder.LaminationSide !== "" ? (
                            <p style={{ textAlign: "left" }}>
                              <b>Finishing </b>: {updateOrder.LaminationSide}
                              &nbsp;{updateOrder.LaminationType}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-sm-4" align="center">
                          <h5> {updateOrder.Quantity}</h5>
                        </div>
                      </div>
                    </div>
                    <div
                      className="card-footer"
                      style={{
                        backgroundColor: "rgb(6,120,139)",
                        color: "white",
                      }}
                    >
                      {/* <h6 align="end">Cover Cost : {NewPaperCost()} </h6>
                  <h6 align="end">Cover Print Cost : {NewPrintCost()} </h6>
                  {(updateOrder.LaminationFabricFinishing === true) &
                  (updateOrder.LaminationType !== '') &
                  (updateOrder.LaminationSide !== '') ? (
                    <h6 align="end">Lamination Cost : {newLaminationCost()} </h6>
                  ) : null}
                  {updateOrder.binding !== '' ? (
                    <h6 align="end">Binding Cost : {newBindingCost()} </h6>
                  ) : null} */}

                      <h5 align="center">Total Price : {newTotalCost()} </h5>
                    </div>
                  </div>
                  <div style={{ paddingTop: "2%" }}>
                    <button className="btn btn-sm btn-primary" onClick={() => SubmitOrderHandler()}>
                      Add To Cart
                    </button>
                  </div>
                </div>
                {/* <div className="col-sm-6">
              <div className="col-sm-12 mb-3">
                <div
                  className="card card1"
                  style={{ outline: 'none' }}
                  onClick={() => props.history.push('Browsedesign')}
                >
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="card-body px-auto">
                        <i
                          className="fa fa-picture-o"
                          style={{ fontSize: '3.5rem' }}
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                    <div className="col-sm-10">
                      <div className="card-body">
                        <a href='http://www.reyprinters.com.s3-website.ap-south-1.amazonaws.com/designs' style={{ textDecoration: "none", color: "black" }}><h3 className="card-title cardmargin">Browse Design</h3></a>
                        <p className="card-title cardmargin">
                          Choose from one of our design templates{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 mb-3">
                <div
                  className="card card1"
                  style={{ outline: 'none' }}
                  onClick={() => props.history.push('Uploaddesign')}
                >
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="card-body px-auto">
                        <i
                          className="fa fa-upload"
                          style={{ fontSize: '3.5rem' }}
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                    <div className="col-sm-10">
                      <div className="card-body">
                        <h3 className="card-title cardmargin">Upload Your Design</h3>
                        <p className="card-title cardmargin">Upload your Print Ready Design </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 mb-3">
                <div className="card card1" style={{ outline: 'none' }}>
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="card-body px-auto" style={{ verticalAlign: 'baseline' }}>
                        <i
                          className="fa fa-object-ungroup"
                          style={{ fontSize: '3.5rem' }}
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                    <div className="col-sm-10">
                      <div className="card-body">
                        <a href='http://www.reyprinters.com.s3-website.ap-south-1.amazonaws.com/designs/tool' style={{ textDecoration: "none", color: "black" }}><h3 className="card-title cardmargin">Custom Design</h3></a>
                        <p className="card-title cardmargin">
                          Create your design from a blank canvas{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
              </div>
            </div>
          ) : null}
          {showCart ? <Cart goToHome={goToHome} /> : null}
          <Modal isOpen={editUser} toggle={onCloseModal} classNames="">
            <ModalBody>
              <div class="container">
                <div style={{ textAlign: "center" }}>If you are already a member !</div>
                <div
                  class="row"
                  style={{
                    marginLeft: "20%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <button
                    onClick={() => setShowLogin(true)}
                    style={{
                      width: "27%",
                      border: "none",
                      background: "#0d6efd",
                      borderRadius: 5,
                      color: "white",
                    }}
                  >
                    Login
                  </button>

                  <button
                    onClick={() => setShowLogin(true)}
                    style={{
                      width: "27%",
                      marginLeft: "20%",
                      border: "none",
                      background: "#0d6efd",
                      borderRadius: 5,
                      color: "white",
                    }}
                  >
                    SignUp
                  </button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.orders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeorders: (orders) => {
      dispatch(changeOrders(orders));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
