import React from "react";
import img from "../Images/logo2.jpg";
import { connect } from "react-redux";
import { changeOrders } from "../../actions/action";

const Header = (props) => {
  const token = localStorage.getItem("token");
  const isLoggedIn = token ? true : false;
  return (
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid" style={{ paddingRight: "10%" }}>
        <a class="navbar-brand" href="#" style={{ paddingLeft: "10.5%" }}>
          <img src={img} height="50" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
          <ul class="navbar-nav">
            <li class="nav-item ">
              <a class="nav-link mx-auto " id="navbar" role="button" aria-expanded="false" href="#">
                <b> Home</b>{" "}
              </a>
            </li>
            {isLoggedIn ? (
              <li class="nav-item  mx-auto">
                <a
                  class="nav-link mx-auto "
                  id="navbar"
                  role="button"
                  aria-expanded="false"
                  href="#/Order"
                >
                  <b>Orders</b>
                </a>
              </li>
            ) : null}
            <li class="nav-item   mx-auto">
              <a
                class="nav-link "
                id="navbar"
                role="button"
                aria-expanded="false"
                href="#/Contactus"
              >
                {" "}
                <b> Contact Us</b>{" "}
              </a>
            </li>
            <li class="nav-item   mx-auto">
              <a
                class="nav-link "
                id="navbar"
                role="button"
                aria-expanded="false"
                href="#/CartList"
              >
                <b> Cart</b>
                <i class="fa fa-shopping-cart m-1" aria-hidden="true"></i>{" "}
                {isLoggedIn ? (
                  props.orders.length ? (
                    <span class="badge badge-warning" id="lblCartCount">
                      {props.orders.length}
                    </span>
                  ) : null
                ) : null}
              </a>
            </li>{" "}
            {isLoggedIn ? (
              <li class="nav-item   mx-auto">
                <a
                  class="nav-link "
                  id="navbar"
                  role="button"
                  aria-expanded="false"
                  href="#/Profile"
                >
                  {" "}
                  <b>Profile</b>{" "}
                </a>
              </li>
            ) : null}
            {isLoggedIn ? (
              <li
                class="nav-item "
                onClick={() => {
                  localStorage.removeItem("token");
                  setTimeout(() => {
                    window.location.reload();
                  }, 200);
                }}
              >
                <a
                  class="nav-link  mx-auto "
                  id="navbar"
                  role="button"
                  aria-expanded="false"
                  href="#/Login"
                >
                  <b> Logout</b>
                  <i class="fa fa-user m-1" aria-hidden="true"></i>{" "}
                </a>
              </li>
            ) : (
              <li class="nav-item  fa-fa-user   mx-auto">
                <a class="nav-link " id="navbar" role="button" aria-expanded="false" href="#/Login">
                  <b> Login</b>
                  <i class="fa fa-user m-1" aria-hidden="true"></i>{" "}
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.orders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeorders: (orders) => {
      dispatch(changeOrders(orders));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
