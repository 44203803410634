import "./App.css";
import { HashRouter, Switch, Route } from "react-router-dom";
import Login from "./components/Login";
import Header from "./components/Header/Header";
import Home from "./components/Home";
import Signup from "./components/Signup";
import Footer from "./components/Footer/Footer";
import Bookprinting from "./components/Bookprinting";
import Brochure from "./components/Brochure";
import Customized from "./components/Customized";
import Danglerprinting from "./components/Danglerprinting";
import Flyer from "./components/Flyer";
import Letterhead from "./components/Letterhead";
import Posterprinting from "./components/Posterprinting";
import Sheetwise from "./components/Sheetwise";
import Cart from "./components/Cart";
import CartList from "./components/CartList";
import Digitalprinting from "./components/Digitalprinting";
import Offsetprinting from "./components/Offsetprinting";
import Browsedesign from "./components/Browsedesign";
import Uploaddesign from "./components/Uploaddesign";
import Order from "./components/Order";
import Certificate from "./components/Certificate";
import Calendar from "./components/Calendar";
import Diary from "./components/Diary";
import Contactus from "./components/Contactus";
import Profile from "./components/Profile";
import EditProfile from "./components/EditProfile";
import Invoice from "./components/Invoice";
import MultiPageBrochure from "./components/MultiPageBrochure";
import Sticker from "./components/Sticker";
import Envelope from "./components/Envelope";
import NewCartList from "./components/new/CartList";
import NewInvoice from "./components/new/Invoice";
import Files from "./components/Files";
import Register from "./components/Register";

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/Login" component={Login} />
          <Route exact path="/Signup" component={Signup} />
          <Route exact path="/contactus" component={Contactus} />
          <Route exact path="/Cart" component={Cart} />
          <Route exact path="/CartList" component={CartList} />
          <Route exact path="/order" component={Order} />
          <Route exact path="/Bookprinting" component={Bookprinting} />
          <Route exact path="/Brochure" component={Brochure} />
          <Route exact path="/Customized" component={Customized} />
          <Route exact path="/Danglerprinting" component={Danglerprinting} />
          <Route exact path="/Flyer" component={Flyer} />
          <Route exact path="/Letterhead" component={Letterhead} />
          <Route exact path="/Posterprinting" component={Posterprinting} />
          <Route exact path="/Sheetwise" component={Sheetwise} />
          <Route exact path="/Digitalprinting" component={Digitalprinting} />
          <Route exact path="/Offsetprinting" component={Offsetprinting} />
          <Route exact path="/Browsedesign" component={Browsedesign} />
          <Route exact path="/Uploaddesign" component={Uploaddesign} />
          <Route exact path="/certificate" component={Certificate} />
          <Route exact path="/calendar" component={Calendar} />
          <Route exact path="/diary" component={Diary} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/Editprofile" component={EditProfile} />
          <Route exact path="/Invoice" component={Invoice} />
          <Route exact path="/MultiPageBrochure" component={MultiPageBrochure} />
          <Route exact path="/Sticker" component={Sticker} />
          <Route exact path="/Envelope" component={Envelope} />
          <Route exact path="/NewCartList" component={NewCartList} />
          <Route exact path="/NewInvoice" component={NewInvoice} />
          <Route exact path="/Files" component={Files} />
          <Route exact path="/Register" component={Register} />
        </Switch>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
