import React from "react";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import img1 from "./Images/crousel/Banner2.jpg";
import img2 from "./Images/crousel/Banner3.jpg";
import img3 from "./Images/crousel/Banner4.jpg";
import img4 from "./Images/crousel/Banner5.jpg";
import img5 from "./Images/crousel/Banner1.jpg";
import bookpng from "./svgtopng/Book.png";
import brochurepng from "./svgtopng/Brochure.png";
import calendarpng from "./svgtopng/Calendar.png";
import certificatepng from "./svgtopng/Certificate.png";
import diarypng from "./svgtopng/Diary.png";
import envelopepng from "./svgtopng/Envelope.png";
import flyerpng from "./svgtopng/Flyer.png";
import letterheadpng from "./svgtopng/Letterhead.png";
import multipagepng from "./svgtopng/Multipage.png";
import posterpng from "./svgtopng/Poster.png";
import stickerpng from "./svgtopng/Sticker.png";
import filepng from "./svgtopng/file.png";
import registerpng from "./svgtopng/Register.png";

const Home = (props) => {
  return (
    <>
      <div>
        {/* =================================================== */}
        {/* =================================================== */}
        <section>
          <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
            <div
              id="carouselExampleIndicators"
              className="carousel slide w-100"
              data-ride="carousel"
            >
              <div className="container">
                <div className="row p-md-5 p-3">
                  <CarouselProvider naturalSlideWidth={100} naturalSlideHeight={38} totalSlides={5}>
                    <Slider>
                      <Slide index={0}>
                        {" "}
                        <img src={img5} className="w-100" style={{ borderRadius: 12 }} />
                      </Slide>
                      <Slide index={1}>
                        {" "}
                        <img src={img1} className="w-100" style={{ borderRadius: 12 }} />
                      </Slide>
                      <Slide index={2}>
                        {" "}
                        <img src={img2} className="w-100" style={{ borderRadius: 12 }} />
                      </Slide>
                      <Slide index={3}>
                        {" "}
                        <img src={img3} className="w-100" style={{ borderRadius: 12 }} />
                      </Slide>
                      <Slide index={4}>
                        {" "}
                        <img src={img4} className="w-100" style={{ borderRadius: 12 }} />
                      </Slide>
                    </Slider>
                    <ButtonNext className="carousel-control-next">
                      <i
                        class="fa fa-arrow-right fa-2x"
                        style={{ paddingLeft: "65%" }}
                        aria-hidden="true"
                      ></i>
                    </ButtonNext>
                    <ButtonBack className="carousel-control-prev">
                      <i
                        class="fa fa-arrow-left fa-2x"
                        style={{ paddingRight: "65%" }}
                        aria-hidden="true"
                      ></i>
                    </ButtonBack>
                  </CarouselProvider>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row p-md-5 p-3">
              <div className="col-xl-6 col-sm-6 col-md-6 col-6 pr-md-5 pr-3">
                <div
                  className="row justify-content-end"
                  style={{ outline: "none", cursor: "pointer" }}
                >
                  <img
                    src="https://designguru.s3.amazonaws.com/Offset.svg"
                    className="img-fluid w-100"
                    onClick={() => props.history.push("Offsetprinting")}
                  />
                </div>
              </div>
              <div className="col-xl-6 col-sm-6 col-md-6 col-6 pl-md-5 pl-3">
                <div
                  className="row justify-content-start"
                  style={{ outline: "none", cursor: "pointer" }}
                >
                  <img
                    src="https://designguru.s3.amazonaws.com/Digital.svg"
                    className="img-fluid w-100"
                    onClick={() => props.history.push("Digitalprinting")}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ===================================================== */}
        {/* ===================================================== */}
        <section>
          <div className="container">
            <div className="row p-md-5 p-3">
              <div className="jumbotron text-center">
                <h1 className="display-5">Our Process!</h1>
                <p className="lead">
                  India’s First Online Print Solution with Fastest Services Give your requirement
                  &amp; take
                </p>
                <img
                  src="https://designguru.s3.amazonaws.com/new.svg"
                  className="img-fluid"
                  width="700vh"
                />
              </div>
            </div>
          </div>
        </section>
        {/* ===================================================== */}
        {/* ===================================================== */}

        <section>
          <div className="container">
            <div className=" mt-2  p-md-5 p-4">
              <div className="row text-center ">
                <div className="col-md-12 mt-1 ">
                  <h4
                    className="section-heading mt-5 "
                    style={{ fontSize: "35px", paddingBottom: "0rem" }}
                  >
                    OFFSET PRINTING
                  </h4>
                </div>
              </div>
              <div className="row p-md-3">
                <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                  <div className="row p-4 justify-content-center">
                    <div
                      className="card shadow"
                      style={{ outline: "none" }}
                      onClick={() => props.history.push("Posterprinting")}
                    >
                      <img src={posterpng} className="img-fluid w-100 shadow_card_img " />
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                  <div className="row p-4 justify-content-center">
                    <div
                      className="card shadow"
                      style={{ outline: "none" }}
                      onClick={() => props.history.push("Brochure")}
                    >
                      <img src={brochurepng} className="img-fluid w-100 shadow_card_img " />
                    </div>
                  </div>
                </div>

                <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                  <div className="row p-4 justify-content-center">
                    <div
                      className="card  shadow "
                      style={{ outline: "none" }}
                      onClick={() => props.history.push("Flyer")}
                    >
                      <img src={flyerpng} className="img-fluid w-100 shadow_card_img " />
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                  <div className="row p-4 justify-content-center">
                    <div
                      className="card  shadow "
                      style={{ outline: "none" }}
                      onClick={() => props.history.push("Bookprinting")}
                    >
                      <img src={bookpng} className="img-fluid w-100 shadow_card_img " />
                    </div>
                  </div>
                </div>

                <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                  <div className="row p-4 justify-content-center">
                    <div
                      className="card  shadow "
                      style={{ outline: "none" }}
                      onClick={() => props.history.push("Letterhead")}
                    >
                      <img src={letterheadpng} className="img-fluid w-100 shadow_card_img " />
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                  <div className="row p-4 justify-content-center">
                    <div
                      className="card  shadow "
                      style={{ outline: "none" }}
                      onClick={() => props.history.push("Diary")}
                    >
                      <img src={diarypng} className="img-fluid w-100 shadow_card_img " />
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                  <div className="row p-4 justify-content-center">
                    <div
                      className="card  shadow "
                      style={{ outline: "none" }}
                      onClick={() => props.history.push("Certificate")}
                    >
                      <img src={certificatepng} className="img-fluid w-100 shadow_card_img " />
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                  <div className="row p-4 justify-content-center">
                    <div
                      className="card shadow"
                      style={{ outline: "none" }}
                      onClick={() => props.history.push("Calendar")}
                    >
                      <img src={calendarpng} className="img-fluid w-100 shadow_card_img " />
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                  <div className="row p-4 justify-content-center">
                    <div
                      className="card shadow"
                      style={{ outline: "none" }}
                      onClick={() => props.history.push("Envelope")}
                    >
                      <img src={envelopepng} className="img-fluid w-100 shadow_card_img " />
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                  <div className="row p-4 justify-content-center">
                    <div
                      className="card shadow"
                      style={{ outline: "none" }}
                      onClick={() => props.history.push("MultiPageBrochure")}
                    >
                      <img src={multipagepng} className="img-fluid w-100 shadow_card_img " />
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                  <div className="row p-4 justify-content-center">
                    <div
                      className="card shadow"
                      style={{ outline: "none" }}
                      onClick={() => props.history.push("Sticker")}
                    >
                      <img src={stickerpng} className="img-fluid w-100 shadow_card_img " />
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                  <div className="row p-4 justify-content-center">
                    <div
                      className="card shadow"
                      style={{ outline: "none" }}
                      onClick={() => props.history.push("Files")}
                    >
                      <img src={filepng} className="img-fluid w-100 shadow_card_img " />
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                  <div className="row p-4 justify-content-center">
                    <div
                      className="card shadow"
                      style={{ outline: "none" }}
                      onClick={() => props.history.push("Register")}
                    >
                      <img src={registerpng} className="img-fluid w-100 shadow_card_img " />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
