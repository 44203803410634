import "pure-react-carousel/dist/react-carousel.es.css";
import React from "react";
import bookpng from "./svgtopng/Book.png";
import brochurepng from "./svgtopng/Brochure.png";
import calendarpng from "./svgtopng/Calendar.png";
import certificatepng from "./svgtopng/Certificate.png";
import diarypng from "./svgtopng/Diary.png";
import envelopepng from "./svgtopng/Envelope.png";
import flyerpng from "./svgtopng/Flyer.png";
import letterheadpng from "./svgtopng/Letterhead.png";
import multipagepng from "./svgtopng/Multipage.png";
import posterpng from "./svgtopng/Poster.png";
import stickerpng from "./svgtopng/Sticker.png";
import filepng from "./svgtopng/file.png";

const Offsetprinting = (props) => {
  return (
    <>
      {/* <div>
      <img src={img} alt="under construction" width="50%" height="600"/>
      </div> */}
      <section>
        <div style={{ backgroundColor: "rgb(66, 65, 145 )" }}>
          <div className="container mt-sm-4 mt-3">
            <div className="row px-3 px-md-0">
              <div className="col-md-6 col-12">
                <div className="row mt-md-4 p-5">
                  <img
                    src="https://designguru.s3.amazonaws.com/28-04/offset.svg"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-sm-6">
                <div className="row mt-2 p-5 align-middle">
                  <h3 className="pb-4 text-light">
                    <b>What is Offset Printing? </b>
                  </h3>
                  <p className="text-light">
                    Offset printing technology uses plates, usually made from aluminum, which are
                    used to transfer an image onto a rubber "blanket", and then rolling that image
                    onto a sheet of paper. It's called offset because the ink is not transferred
                    directly onto the paper. Because offset presses run so efficiently once they are
                    set up, offset printing is the best choice when larger quantities are needed,
                    and provides accurate color reproduction, and crisp, clean professional looking
                    printing.
                    <br />
                    <br /> We have seen that in offset printing most people have problems in
                    extracting their rate, then we have created an easy rate calculator in which you
                    can easily find different types Prducts (eg -Flyer, sheet wise rate, brochure,
                    booklets, books, multi type items, stickers) of rate.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className=" mt-2  p-md-2 p-2">
            <div className="row text-center ">
              <div className="col-md-12 mt-1 ">
                <h4
                  className="section-heading mt-5 "
                  style={{ fontSize: "20px", paddingBottom: "0rem" }}
                >
                  OFFSET PRINTING PRODUCT
                </h4>
              </div>
            </div>
            <div className="row p-md-3">
              <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                <div className="row p-4 justify-content-center">
                  <div
                    className="card shadow"
                    style={{ outline: "none" }}
                    onClick={() => props.history.push("Posterprinting")}
                  >
                    <img src={posterpng} className="img-fluid w-100 shadow_card_img " />
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                <div className="row p-4 justify-content-center">
                  <div
                    className="card shadow"
                    style={{ outline: "none" }}
                    onClick={() => props.history.push("Brochure")}
                  >
                    <img src={brochurepng} className="img-fluid w-100 shadow_card_img " />
                  </div>
                </div>
              </div>

              <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                <div className="row p-4 justify-content-center">
                  <div
                    className="card  shadow "
                    style={{ outline: "none" }}
                    onClick={() => props.history.push("Flyer")}
                  >
                    <img src={flyerpng} className="img-fluid w-100 shadow_card_img " />
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                <div className="row p-4 justify-content-center">
                  <div
                    className="card  shadow "
                    style={{ outline: "none" }}
                    onClick={() => props.history.push("Bookprinting")}
                  >
                    <img src={bookpng} className="img-fluid w-100 shadow_card_img " />
                  </div>
                </div>
              </div>

              <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                <div className="row p-4 justify-content-center">
                  <div
                    className="card  shadow "
                    style={{ outline: "none" }}
                    onClick={() => props.history.push("Letterhead")}
                  >
                    <img src={letterheadpng} className="img-fluid w-100 shadow_card_img " />
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                <div className="row p-4 justify-content-center">
                  <div
                    className="card  shadow "
                    style={{ outline: "none" }}
                    onClick={() => props.history.push("Diary")}
                  >
                    <img src={diarypng} className="img-fluid w-100 shadow_card_img " />
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                <div className="row p-4 justify-content-center">
                  <div
                    className="card  shadow "
                    style={{ outline: "none" }}
                    onClick={() => props.history.push("Certificate")}
                  >
                    <img src={certificatepng} className="img-fluid w-100 shadow_card_img " />
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                <div className="row p-4 justify-content-center">
                  <div
                    className="card shadow"
                    style={{ outline: "none" }}
                    onClick={() => props.history.push("Calendar")}
                  >
                    <img src={calendarpng} className="img-fluid w-100 shadow_card_img " />
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                <div className="row p-4 justify-content-center">
                  <div
                    className="card shadow"
                    style={{ outline: "none" }}
                    onClick={() => props.history.push("Envelope")}
                  >
                    <img src={envelopepng} className="img-fluid w-100 shadow_card_img " />
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                <div className="row p-4 justify-content-center">
                  <div
                    className="card shadow"
                    style={{ outline: "none" }}
                    onClick={() => props.history.push("MultiPageBrochure")}
                  >
                    <img src={multipagepng} className="img-fluid w-100 shadow_card_img " />
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                <div className="row p-4 justify-content-center">
                  <div
                    className="card shadow"
                    style={{ outline: "none" }}
                    onClick={() => props.history.push("Sticker")}
                  >
                    <img src={stickerpng} className="img-fluid w-100 shadow_card_img " />
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-md-3 col-lg-3 ">
                <div className="row p-4 justify-content-center">
                  <div
                    className="card shadow"
                    style={{ outline: "none" }}
                    onClick={() => props.history.push("Files")}
                  >
                    <img src={filepng} className="img-fluid w-100 shadow_card_img " />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section>
          <div style={{ backgroundColor: 'rgb(7, 105,144)' }}>
            <div className="container">
              <div className="row p-5">
                <div className="col-xl-5 col-md-5 pt-sm-5">
                  <div className="row justify-text-centerleft  text-light">
                    <h1>Estimation Calculator</h1>
                    <p>
                      We have brought for you a printing calculator so that you can easily order for
                      printing without any hassle by knowing the rates of printing.
                    </p>
                    <button type="button" className="btn btn-outline-light">
                      Calculator
                    </button>
                  </div>
                </div>
                <div className="col-xl-7 col-md-7 ">
                  <div className="row justify-content-end mt-3 mt-md-0">
                    <div className="row p-sm-4 p-4">
                      <img
                        src="https://designguru.s3.amazonaws.com/Offset+Printing/Calculator.svg"
                        className="img-fluid"
                        width="500vh"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      {/* ================================================================ */}
      {/* ================================================================ */}
      <section>
        <div style={{ backgroundColor: "rgb(0, 0, 0 )" }}>
          <div className="container p-4">
            <h1 className="text-center text-light mb-3">ADVANTAGES</h1>
            <div className="row justify-content-center mt-2">
              <div className="col-6 col-sm">
                <div className="row justify-content-center p-2">
                  <img
                    src="https://designguru.s3.amazonaws.com/Digital+Printing/09.svg"
                    className="img-fluid w-75"
                  />
                  <h6 className="text-center text-light pt-2">Online Calculation</h6>
                </div>
              </div>
              <div className="col-6 col-sm">
                <div className="row justify-content-center p-2">
                  <img
                    src="https://designguru.s3.amazonaws.com/Digital+Printing/10.svg"
                    className="img-fluid w-75"
                  />
                  <h6 className="text-center text-light pt-2">Time Saving</h6>
                </div>
              </div>
              <div className="col-6 col-sm">
                <div className="row justify-content-center p-2">
                  <img
                    src="https://designguru.s3.amazonaws.com/Digital+Printing/11.svg"
                    className="img-fluid w-75"
                  />
                  <h6 className="text-center text-light pt-2">Cost Efectively Printing</h6>
                </div>
              </div>
              <div className="col-6 col-sm">
                <div className="row justify-content-center p-2">
                  <img
                    src="https://designguru.s3.amazonaws.com/Digital+Printing/12.svg"
                    className="img-fluid w-75"
                  />
                  <h6 className="text-center text-light pt-2">Quality Printing</h6>
                </div>
              </div>
              <div className="col-6 col-sm">
                <div className="row justify-content-center p-2">
                  <img
                    src="https://designguru.s3.amazonaws.com/Digital+Printing/13.svg"
                    className="img-fluid w-75"
                  />
                  <h6 className="text-center text-light text-center pt-2">Variable Variety</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ================================================================ */}
      {/* ================================================================ */}

      {/* ================================================================ */}
      {/* ================================================================ */}
      {/* <section>
          <div style={{ backgroundColor: 'rgb(0, 0,0  )' }}>
            <div className="container">
              <div className="row justify-content-center px-3 ">
                <h1 className="text-center text-light py-4"> Our Infrastructure </h1>
                <img
                  src="https://designguru.s3.amazonaws.com/Offset+Printing/bg-1.svg"
                  className="img-fluid"
                  width="1000vh"
                />
              </div>
              <div className="row  py-5 justify-content-center">
                <div className="col-sm-6">
                  <div className="row p-2">
                    <div className="col-3">
                      <div className="row justify-content-end pr-2">
                        <i
                          className="fa fa-check p-1 "
                          aria-hidden="true"
                          style={{ fontSize: '16px', color: 'rgb(7, 110, 144)' }}
                        />
                      </div>
                    </div>
                    <div className="col-9">
                      <div className="row">
                        <h5 className="text-light pl-2">
                          {'{'}
                          {'{'}x{'}'}
                          {'}'}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
    </>
  );
};

export default Offsetprinting;
