import React, { useState, useEffect } from "react";
import axios from "axios";
import { url } from "./helper";
import imglogo from "./logo2.jpg";
import PrintProvider, { Print, NoPrint } from "react-easy-print";

const Cart = (props) => {
  const url3 = `${url}/api/billingaddress`;
  const [userDetail, setUserDetail] = useState([]);
  const getApiMathod3 = async () => {
    await axios.get(url3).then((response) => {
      setUserDetail(response.data.data);
    });
  };
  useEffect(async () => {
    getApiMathod3();
  }, []);
  const [customerData, setCustomerData] = useState({});
  const orderId = props.orderId;
  const url2 = `${url}/api/order`;
  useEffect(async () => {
    getApiMathod();
  }, []);

  const getApiMathod = async () => {
    await axios.get(`${url2}/${orderId}`).then((response) => {
      setCustomerData(response.data.data);
    });
  };

  useEffect(async () => {
    getAddress();
  }, []);

  const [addressData, setAddressData] = useState({});
  const getAddress = async () => {
    await axios.get(`${url2}/address/${orderId}`).then((response) => {
      if (response.data.data !== null) {
        setAddressData(response.data.data);
      }
    });
  };
  const finalData = userDetail.filter((i) => i._id === customerData.billingAddress);
  const finalData1 = finalData.length > 0 ? finalData[0] : {};

  const [gstData, setGstData] = useState([]);
  const gst = (x) => {
    if (gstData.filter((i) => i.product === x).length > 0) {
      const gst = gstData.filter((i) => i.product === x)[0].gst;
      return gst;
    } else {
      return 0;
    }
  };
  useEffect(async () => {
    getGst();
  }, []);

  const getGst = async () => {
    await axios.get(`${url}/api/gst`).then((response) => {
      setGstData(response.data.data);
    });
  };
  return (
    <>
      <PrintProvider>
        {/* <div>
        <div className="container">
          <i
            onClick={() => props.closeInvoice()}
            className="fa fa-times fa-2x "
            style={{ float: 'right', color: 'rgb(84,84,156)' }}
          ></i>
          <div className="col-md-12">
            <div className="invoice">
              
              <div className="invoice-company text-inverse f-w-600">
                <span className="pull-right hidden-print">
                  <button
                    className="btn btn-sm btn-info m-b-10 p-l-5"
                    onClick={() => window.print()}
                  >
                    <i className="fa fa-print t-plus-1 fa-fw fa-lg" /> Print
                  </button>
                </span>
                <a href=""></a>
                Print Bajar

              </div>
              <div className="invoice-header">
                <div className="invoice-from">
                  <address className="m-t-5 m-b-5">
                    <strong className="text-inverse">INVOICE ID - {orderId.substr(0,6)}</strong>
                    <br />
                    Address: {addressData.address}
                    <br />
                    City: {addressData.city}, Pincode: {addressData.pincode}
                    <br />
                    Phone: {addressData.mobile}
                    <br />
                  </address>
                </div>
                <div className="invoice-to" />
                <div className="invoice-date">
                  <div className="invoice-detail">
                    <b>Bill To</b>
                  </div>
                  <div className="invoice-detail">
                    {addressData.name}
                    <br />
                   
                    <div className="date text-inverse m-t-5">Invoice Date</div>
                    <div className="date text-inverse m-t-5">{customerData.date}</div>
                  </div>
                </div>
              </div>
              <div className="invoice-content">
                <div className="table-responsive">
                  <table className="table table-invoice">
                    <thead>
                      <tr>
                        <th>ORDER</th>
                        <th className="text-center" width="10%">
                          RATE
                        </th>
                        <th className="text-center" width="10%">
                          GST
                        </th>
                        <th className="text-right" width="20%">
                          TOTAL
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">{customerData.orderFor}</td>
                        <td className="text-center">₹{customerData.totalPrice}</td>
                        <td className="text-center">
                          ₹{(customerData.totalPrice * gst(customerData.orderFor)) / 100}
                        </td>
                        <td className="text-right">
                          ₹{(customerData.totalPrice * (100 + gst(customerData.orderFor))) / 100}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              
                <div className="invoice-price">
                  <div className="invoice-price-left">
                    <div className="invoice-price-row">
                      <div className="sub-price">
                        <small>GRAND SUBTOTAL</small>
                        <span className="text-inverse">₹{customerData.totalPrice}</span>
                      </div>
                      <div className="sub-price">
                        <i className="fa fa-plus text-muted" />
                      </div>
                      <div className="sub-price">
                        <small>GST({gst(customerData.orderFor)}%)</small>
                        <span className="text-inverse">
                          {' '}
                          ₹{(customerData.totalPrice * gst(customerData.orderFor)) / 100}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="invoice-price-right">
                    <small>TOTAL</small>{' '}
                    <span className="f-w-600">
                      ₹{(customerData.totalPrice * (100 + gst(customerData.orderFor))) / 100}
                    </span>
                  </div>
                </div>
         
              </div>
            
              <div className="invoice-footer">
                <p className="text-center m-b-5 f-w-600">THANK YOU FOR YOUR BUSINESS</p>
              </div>
           
            </div>
          </div>
        </div>
      </div> */}

        <i
          onClick={() => props.closeInvoice()}
          className="fa fa-times fa-2x "
          style={{ float: "right", color: "rgb(84,84,156)", marginRight: 10 }}
        ></i>
        <button
          className="btn btn-sm btn-info m-b-10 p-l-5"
          style={{ float: "right", marginRight: 10 }}
          onClick={() => window.print()}
        >
          <i className="fa fa-print t-plus-1 fa-fw fa-lg" /> Print
        </button>
        <Print single name="foo">
          <div style={{ width: "90%", paddingLeft: "12%" }}>
            <div style={{ border: "3px solid black" }}>
              <div className="container pt-3">
                <div className="row">
                  <div className="col">
                    <a className href="#">
                      <img height={40} src={imglogo} alt="printbazar" />
                    </a>
                  </div>
                  <div className="col">
                    <p>
                      Plot No. 210, Next to Hotel Amer Palace,Zone-1,M.P. Nagar, Bhopal |
                      Mob:7880003671-73
                      <br />
                      Email : info@printbajar.com, Web : www.printbajar.com
                      <br />
                      GSTIN :{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body">Invoice No.{orderId.substr(0, 6)}</div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card">
                      <div className="card-body">Invoice No.{orderId.substr(0, 6)}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container pt-3">
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body">
                        Bill To, {finalData1?.name}
                        <address className="">
                          <br />
                          Address: {finalData1?.address}
                          <br />
                          City: {finalData1?.city}, Pincode: {finalData1?.pincode}
                          <br />
                        </address>
                        <div>
                          GSTIN:
                          <br />
                          Mob:{finalData1?.mobile}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card">
                      <div className="card-body">
                        Supply To, {finalData1?.name}
                        <address>
                          <br />
                          Address: {finalData1?.address}
                          <br />
                          City: {finalData1?.city}, Pincode: {finalData1?.pincode}
                        </address>
                        <div>
                          GSTIN:
                          <br />
                          Mob: {finalData1?.mobile}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Description</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Rate</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        {customerData.orderFor}
                        <br />
                        {customerData.size} size on {customerData.gsm} gsm {customerData.printSide}
                      </th>
                      <td>{customerData.Quantity}</td>
                      <td>₹{customerData.totalPrice / customerData.Quantity}</td>
                      <td>₹{customerData.totalPrice}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <div className="row">
                  <div className="col-sm-8">
                    <div className="col">
                      <div className="card">
                        <div className="card-body">Payment Type</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="row">Total</th>
                          <td>₹{customerData.totalPrice}</td>
                        </tr>
                        <tr>
                          <th scope="row">GST {gst(customerData.orderFor)}%</th>
                          <td>₹{(customerData.totalPrice * gst(customerData.orderFor)) / 100}</td>
                        </tr>
                        <tr>
                          <th scope="row">G.Total</th>
                          <td>
                            ₹{(customerData.totalPrice * (100 + gst(customerData.orderFor))) / 100}
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
              <div className="container">
                <div style={{ textAlign: "right", paddingBottom: "50px" }}>
                  Authorised Signatory
                </div>
              </div>
            </div>
          </div>
        </Print>
      </PrintProvider>
    </>
  );
};

export default Cart;
