import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "fontawesome";
import "bootstrap";
import { createStore } from "redux";
import { Provider } from "react-redux";
import reducer from "./reducers/reducer";
import { loadState, saveState } from "./localstorage";
import throttle from "lodash/throttle";

const persistedState = loadState();
const store = createStore(reducer, persistedState);

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000),
);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root"),
);

reportWebVitals();
