import React from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import {
  CButton,
  CCard,
  CCol,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormInput,
  CForm,
} from "@coreui/react";

function CustomTable({ columns, data, userEditHandeler, userInvoiceHandeler }) {
  const props = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = props;
  console.log(props);
  React.useEffect(() => {
    console.log(globalFilter);
  }, [globalFilter]);

  return (
    <>
      <CCard className="mb-4">
        <CForm className="row g-3">
          <CCol xs="auto">
            <CFormInput
              type="text"
              placeholder="Search"
              value={globalFilter || ""}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
          </CCol>
        </CForm>
        <CTable className="mb-0 border" hover responsive {...getTableProps()}>
          <CTableHead color="light">
            {headerGroups.map((headerGroup) => (
              <CTableRow className="text-center" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <CTableHeaderCell {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </CTableHeaderCell>
                ))}
              </CTableRow>
            ))}
          </CTableHead>
          <CTableBody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              const x = page.length;
              const id = pageIndex * pageSize;
              const newIndex = id + index;
              return (
                <CTableRow v-for="item in tableItems" {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return (
                      <CTableDataCell className="text-center" {...cell.getCellProps()}>
                        {i === 0 ? (
                          newIndex + 1
                        ) : i === 4 ? (
                          data[newIndex].status
                        ) : i === 5 ? (
                          <>
                            <button
                              className="btn-success"
                              style={{
                                padding: 6,
                                borderRadius: 5,
                                border: "none",
                                margin: 5,
                              }}
                              onClick={() => userEditHandeler(data[newIndex]._id)}
                            >
                              View
                            </button>
                            <button
                              className="btn-success"
                              style={{
                                padding: 6,
                                borderRadius: 5,
                                border: "none",
                                margin: 5,
                              }}
                              onClick={() => userInvoiceHandeler(data[newIndex]._id)}
                            >
                              Invoice
                            </button>
                          </>
                        ) : (
                          cell.render("Cell")
                        )}
                      </CTableDataCell>
                    );
                  })}
                </CTableRow>
              );
            })}
          </CTableBody>
        </CTable>
      </CCard>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>

      <br />
      <div>Showing the first 20 results of {rows.length} rows</div>
      <div>
        <pre>
          <code>{JSON.stringify(state.filters, null, 2)}</code>
        </pre>
      </div>
    </>
  );
}

export default CustomTable;
