import React from "react";

const Uploaddesign = () => {
  return (
    <>
      <div className="container">
        <div className="row my-3">
          <div className="col-sm-8">
            <h4>Upload Design</h4>
            <p style={{ fontSize: "14px" }}>
              Please upload jpg,jpeg,gif,png,eps,ai,pdf,cdr,psd,svg images only File should not be
              larger than 500MB in size
            </p>
            <div className="card">
              <div className="card-header">
                <form className="form-inline">
                  <div className="form-group">
                    <label htmlFor="bookingID">Job Name</label>
                    <input type="text" id="disabledInput" className="form-control mx-sm-3" />
                  </div>
                </form>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="card-body">
                    <button className="w-100 btn btn-info">
                      <i className="fa fa-upload m-2" aria-hidden="true" />
                      Upload Front Side File <i aria-hidden="true" />
                    </button>
                    <input style={{ display: "none" }} type="file" multiple="false" />
                    <div>
                      <div className="row">
                        <div className="rounded bg-light m-2 p-2 w-100 text-center">
                          <button className="btn btn-sm btn-primary"> delete</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card-body">
                    <button className="w-100 btn btn-info">
                      <i className="fa fa-upload m-2" aria-hidden="true" />
                      Upload Back Side File <i aria-hidden="true" />
                    </button>
                    <input style={{ display: "none" }} type="file" multiple="false" />
                    <div>
                      <div className="row">
                        <div className="rounded bg-light m-2 p-2 w-100 text-center">
                          <button className="btn btn-sm btn-primary"> delete</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="my-3">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-8">Specification</div>
                    <div className="col-sm-4" align="center">
                      Quantity
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-8">
                      <h4 className="card-title">Sheet Printing</h4>
                      <p className="card-text">
                        18 x 23 size at 90 gsm on Art Paper paper
                        <br />
                        Multicolor Both Side
                      </p>
                      <p>
                        <strong> Finishing </strong>
                        Gloss Lamination Both Side
                      </p>
                    </div>
                    <div className="col-sm-4" align="center">
                      <h5>4000 No.</h5>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <h6 align="end">Total Price : 20000 </h6>
                  <div className="btn-group justify-content-between w-100">
                    <button className=" btn btn-info">
                      Add to Cart <i className="fa fa-shopping-cart" aria-hidden="true" />
                    </button>
                    <button className="btn btn-info mx-1">
                      Continue <i className="fa fa-shopping-cart" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Uploaddesign;
