import axios from "axios";
import OTPInput, { ResendOTP } from "otp-input-react";
import React, { useState } from "react";
import { url } from "./helper";

const Login = (props) => {
  const currentpage = localStorage.getItem("currentpage");
  const isOrder = currentpage ? true : false;
  const [loginData, setLoginData] = useState({
    number: "",
  });
  const validatePhone = (input_str) => {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

    return re.test(input_str);
  };
  const setForm = (event) => {
    let { name, value } = event.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (validatePhone(loginData.number) === false) {
      alert("Enter a Valid Phone Number");
    } else {
      axios.post(`${url}/api/addnew/genrate`, loginData).then((res) => {
        setShowValidateOtp(true);
        alert(`OTP has been sent on ${loginData.number}`);
        console.log(res);
      });
    }
  };
  const showAlert = () => {
    setOTP("");
    alert("enter correct otp");
  };
  const [showValidateOtp, setShowValidateOtp] = useState(false);
  const [OTP, setOTP] = useState("");

  const validate = () => {
    const item = {
      number: loginData.number,
      otp: OTP,
    };
    axios
      .post(`${url}/api/addnew/varify`, item)
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("businessid", res.data.userDetails._id);
        localStorage.setItem("number", loginData.number);
        props.history.push("/");
        window.location.reload();
      })
      .catch((err) => {
        if (OTP !== "") {
          showAlert();
        }
        setShowValidateOtp(false);
        setTimeout(() => {
          setShowValidateOtp(true);
        }, 100);
      });
  };
  return (
    <>
      {OTP.length === 6 ? validate() : null}
      <div className="container-fluid">
        <div className="row no-gutter">
          <div
            className="d-none d-md-block col-md-4 col-lg-6"
            style={{ backgroundColor: "rgb(7, 105, 144)" }}
          >
            <div className="row justify-content-center mt-5">
              <img
                src="https://designguru.s3.amazonaws.com/28-04/login.svg"
                className="pt-5"
                style={{ width: "80%" }}
              />
            </div>
          </div>
          <div className="col-md-8 col-lg-6">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-8 mx-auto">
                    <h2 className="login-heading mb-4">
                      <strong> Welcome back! </strong>
                    </h2>
                    <h6 style={{ float: "left" }}>
                      <b>Mobile</b>
                    </h6>
                    <div class="form-outline mb-4">
                      <input
                        type="mobile"
                        id="form1Example13"
                        class="form-control form-control-lg"
                        name="number"
                        placeholder="Enter Your Number"
                        onChange={(e) => setForm(e)}
                      />
                      <label class="form-label" for="form1Example13"></label>
                    </div>

                    <div class="form-check"></div>
                  </div>
                  <div className=" ">
                    <button
                      onClick={() => handleSubmit()}
                      type="button"
                      className="btn btn-primary btn-lg"
                      style={{
                        paddingLeft: "10%",
                        paddingRight: "10%",
                      }}
                    >
                      Send OTP
                    </button>
                  </div>
                  {showValidateOtp ? (
                    <div
                      class="container height-100 d-flex justify-content-center align-items-center"
                      style={{ paddingTop: "10%" }}
                    >
                      <div class="position-relative">
                        <div class="card p-2 text-center">
                          <h6>
                            Please enter the one time password <br></br> to verify your account
                          </h6>
                          <div>
                            {" "}
                            <span>A code has been sent to</span> <small>{loginData.number}</small>{" "}
                          </div>
                          <div id="otp" class="inputs d-flex flex-row justify-content-center mt-2">
                            <div class="fi_otp_wrapper">
                              <OTPInput
                                value={OTP}
                                onChange={setOTP}
                                autoFocus
                                OTPLength={6}
                                otpType="number"
                                disabled={false}
                                // secure
                              />
                            </div>
                          </div>
                        </div>
                        <ResendOTP onResendClick={() => handleSubmit()} />
                        {/* <div class="card-2" onClick={() => handleSubmit()}>
                          <div class="content d-flex justify-content-center align-items-center">
                            {' '}
                            <span>Didn't get the code</span>
                            Resend
                          </div>
                        </div> */}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
