import React from "react";
import img from "./Images/construction3.png";

const Digitalprinting = () => {
  return (
    <>
      <div>
        <img src={img} alt="under construction" width="50%" height="600" />
      </div>
      {/* <section>
          <div style={{ backgroundColor: 'rgb(0, 0, 0)' }}>
            <div className="container p-5">
              <div className="row">
                <div className="col-sm-12 col-md-8 col-12">
                  <div className="row">
                    <img
                      src="https://designguru.s3.amazonaws.com/Digital+Printing/bg-2.svg"
                      width="600vh"
                    />
                  </div>
                </div>
                <div className=" col-sm-12 col-md-4 col-12">
                  <div className="row justify-text-center mt-4 text-light">
                    <h1>
                      Online <br /> Tracking
                    </h1>
                    <p>
                      We will provide you an <strong> ORDER ID</strong> that <br /> will allow you
                      to know the status of your work.
                    </p>
                    <br />
                    <button type="button" className="btn btn-outline-info">
                      Tracking
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* =========================================================== */}
      {/* =========================================================== */}
      {/* <section>
          <div className="container">
            <div className="row p-md-5 p-4">
              <div className="col-md-7 col-sm-12 col-12">
                <div className="row">
                  <h3>What is Digital Printing?</h3>
                  <p className="text-justify">
                    Digital printing doesn't use plates the way offset does, but instead uses
                    options such as toner (like in laser printers) or larger printers that do use
                    liquid ink. Digital printing shines when lower quantities are needed; think of a
                    run of 20 greeting cards or 100 flyers, poster, brochures etc.. Another benefit
                    of digital printing is it's variable data job (such as certificate) capability .
                    When each piece needs a unique code, name or address, digital is the only way to
                    go. Offset printing cannot accommodate this need. Learn more about digital
                    printing options and capabilities. <br />
                    <br /> While offset printing is a fantastic way to produce great-looking print
                    projects, many businesses or individuals do not need large runs of 500 or more,
                    and the best solution is digital printing.
                  </p>
                </div>
              </div>
              <div className="col-md-5 p-5 ">
                <div className="row justify-text-center">
                  <img
                    src="https://designguru.s3.amazonaws.com/28-04/digital.svg"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section> */}
      {/* =========================================================== */}
      {/* =========================================================== */}
      {/* <section>
          <div style={{ backgroundColor: 'rgb(7, 105, 144)' }}>
            <div className="container p-5">
              <div className="row">
                <div className="col-sm-6 mt-4 d-block d-md-none">
                  <div className="row">
                    <img
                      src="https://designguru.s3.amazonaws.com/Digital+Printing/Join+us.svg"
                      width="500vh"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row justify-text-center mt-4  text-light">
                    <h1> Benefits of Joining</h1>
                  </div>
                  <div className="row">
                    <h4 className="text-light">
                      <i
                        className="fa fa-check p-1 "
                        aria-hidden="true"
                        style={{ fontSize: '16px', color: 'white' }}
                      />{' '}
                      Time saving
                    </h4>
                  </div>
                  <div className="row">
                    <h6 className="text-light">
                      <i
                        className="fa fa-check p-1 "
                        aria-hidden="true"
                        style={{ fontSize: '16px', color: 'white' }}
                      />
                      Cost effectively Printing
                    </h6>
                  </div>
                  <div className="row">
                    <h6 className="text-light">
                      <i
                        className="fa fa-check p-1 "
                        aria-hidden="true"
                        style={{ fontSize: '16px', color: 'white' }}
                      />{' '}
                      Providing a variety of paper
                    </h6>
                  </div>
                  <div className="row">
                    <h6 className="text-light">
                      <i
                        className="fa fa-check p-1 "
                        aria-hidden="true"
                        style={{ fontSize: '16px', color: 'white' }}
                      />{' '}
                      Online Calculation
                    </h6>
                  </div>
                  <div className="row">
                    <h6 className="text-light">
                      {' '}
                      <i
                        className="fa fa-check p-1 "
                        aria-hidden="true"
                        style={{ fontSize: '16px', color: 'white' }}
                      />
                      Printing quality with colour fidelity
                    </h6>
                  </div>
                  <button type="button" className="btn btn-light mt-4">
                    Hurry Up !
                  </button>
                </div>
                <div className="col-sm-6 mt-4 d-none d-md-block">
                  <div className="row p-3">
                    <img
                      src="https://designguru.s3.amazonaws.com/Digital+Printing/Join+us.svg"
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>  */}
    </>
  );
};

export default Digitalprinting;
