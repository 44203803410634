import React from "react";

function card() {
  return (
    <div>
      <div className="container" style={{ border: "2px solid black", borderRadius: "10px" }}>
        <div className="container">
          <div className="row row-cols-1 row-cols-md-3" style={{ paddingLeft: "5%" }}>
            <div className="col">
              <div
                className="card"
                style={{
                  maxWidth: "18rem",
                  borderRadius: "20px",
                  border: "2px dotted black",
                  margin: "5px",
                }}
              >
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      fontSize: "large",
                      fontStyle: "oblique",
                      backgroundColor: "rgb(65, 65, 168)",
                      textAlign: "center",
                      color: "white",
                      borderRadius: "4px",
                    }}
                  >
                    Front Side JPEG File
                  </h5>
                  <p className="card-text" style={{ textAlign: "center" }}>
                    Choose a file
                    <br /> or Drag it here
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              <div
                className="card"
                style={{
                  maxWidth: "18rem",
                  borderRadius: "20px",
                  border: "2px dotted black",
                  margin: "5px",
                }}
              >
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      fontSize: "large",
                      fontStyle: "oblique",
                      backgroundColor: "rgb(75, 75, 187)",
                      textAlign: "center",
                      color: "white",
                      borderRadius: "4px",
                    }}
                  >
                    Back Side JPEG File
                  </h5>
                  <p className="card-text" style={{ textAlign: "center" }}>
                    Choose a file <br />
                    or Drag it here
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              <div
                className="card"
                style={{
                  maxWidth: "18rem",
                  borderRadius: "20px",
                  border: "2px dotted black",
                  margin: "5px",
                }}
              >
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      fontSize: "large",
                      fontStyle: "oblique",
                      backgroundColor: "rgb(67, 67, 158)",
                      textAlign: "center",
                      color: "white",
                      borderRadius: "4px",
                    }}
                  >
                    Upload Coral/pdf File
                  </h5>
                  <p className="card-text" style={{ textAlign: "center" }}>
                    Choose a file
                    <br /> or Drag it here
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row" style={{ paddingLeft: "5%" }}>
            <div className="col" style={{ width: "20px" }}>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "25%" }}
                  aria-valuenow={25}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
              File Name.jpg
            </div>
            <div className="col" style={{ width: "50px" }}>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "25%" }}
                  aria-valuenow={25}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
              File Name.jpg
            </div>
            <div className="col" style={{ width: "50px" }}>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "25%" }}
                  aria-valuenow={25}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
              File Name.jpg
            </div>
          </div>
        </div>
        <div className="container">
          <div
            style={{
              backgroundColor: "rgb(67, 67, 158)",
              textAlign: "center",
              paddingTop: "5px",
            }}
          >
            UPLOAD MORE DESIGN
          </div>
        </div>
        *Coral file is Necessary for printing
      </div>
    </div>
  );
}

export default card;
