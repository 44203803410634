import React, { useState, useEffect } from "react";
import axios from "axios";
import { url } from "./helper";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import img1 from "./Images/bookprinting/Book01.jpg";
import img2 from "./Images/bookprinting/Book02.jpg";
import img3 from "./Images/bookprinting/Book03.jpg";
import Cart from "./Cart";
import { Modal, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import { changeOrders } from "../actions/action";
//
import Login from "./Login1";
//

const MultiPageBrochure = (props) => {
  const goToHome = () => {
    props.history.push("");
  };

  //
  const [showLogin, setShowLogin] = useState(false);
  const loginoff = () => {
    setShowLogin(false);
    setEditUser(false);
  };
  //
  const token = localStorage.getItem("token");
  const isLoggedIn = token ? true : false;
  const [editUser, setEditUser] = useState(false);
  const businessId = localStorage.getItem("businessid");
  const [updateOrder, setUpdateOrder] = useState({
    businessId: businessId,
    orderFor: "MultiPageBrochure",
    design1: "",
    design2: "",
    cdr: "",
    size: "",
    paperType: "",
    gsm: "",
    printSide: "",
    Quantity: "",
    numberOfcolors: "",
    LaminationFabricFinishing: false,
    LaminationType: "",
    LaminationSide: "",
    addOtherTypePages: false,
    // otherTypeNumberOfPages: '',
    // otherTypePaperType: '',
    // otherTypePrintSide: '',
    // otherTypeGsm: '',
    // otherTypeNumberOfColors: '',
    bookInnerNumberOfPages: "",
    bookInnerPaperType: "",
    bookInnerPrintSide: "",
    bookInnerGsm: "",
    bookInnerNumberOfColors: "",
    binding: "",
  });
  const [showDetails, setShowDetails] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const setForm = (event) => {
    let { name, value } = event.target;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    setUpdateOrder({
      ...updateOrder,
      [name]: value,
    });
  };

  const setFormpt = (event) => {
    let { name, value } = event.target;
    const paperName = value.split("(")[0];
    setUpdateOrder({
      ...updateOrder,
      [name]: paperName,
    });
  };

  const setFormp = (event) => {
    let { name, value } = event.target;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    if (49 > value) {
      setUpdateOrder({
        ...updateOrder,
        [name]: value,
      });
    } else {
      setUpdateOrder({
        ...updateOrder,
        [name]: 48,
      });
      alert("Maximum Limit is 48");
    }
  };
  const SubmitOrderHandler = async () => {
    const totalPrice = newTotalCost();
    const newOrder = {
      ...updateOrder,
      totalPrice: totalPrice,
    };

    if (isLoggedIn) {
      await axios
        .post(`${url}/api/order`, newOrder)
        .then((res) => props.changeorders([...props.orders, res.data.data]))
        .then(() => {
          alert("New Order Added To Cart");
          setShowCart(false);
        });
    } else {
      localStorage.setItem("currentpage", "MultiPageBrochure");
      setEditUser(true);
    }
  };
  const showDetailsHandler = () => {
    if (updateOrder.size === "") {
      alert("select multi page brochure size");
    } else if (updateOrder.Quantity === "") {
      alert("enter multi page brochure quantity");
    } else if (updateOrder.paperType === "") {
      alert("select cover paper type");
    } else if (updateOrder.gsm === "") {
      alert("select cover gsm");
    } else if (updateOrder.numberOfcolors === "") {
      alert("select number of colors for cover");
    } else if (updateOrder.printSide === "") {
      alert("select print side for cover");
    } else if (updateOrder.bookInnerNumberOfPages === "") {
      alert("enter number of pages for inner field");
    } else if (updateOrder.bookInnerPaperType === "") {
      alert("select inner paper type");
    } else if (updateOrder.bookInnerGsm === "") {
      alert("select inner gsm");
    } else if (updateOrder.bookInnerNumberOfColors === "") {
      alert("select number of colors for inner");
    } else if (updateOrder.binding === "") {
      alert("select binding");
    } else if (
      (updateOrder.LaminationType === "") &
      (updateOrder.LaminationFabricFinishing === true)
    ) {
      alert("select lamination type");
    } else if (
      (updateOrder.LaminationSide === "") &
      (updateOrder.LaminationFabricFinishing === true)
    ) {
      alert("select lamination side");
    } else if (
      (updateOrder.otherTypeNumberOfPages === "") &
      (updateOrder.addOtherTypePages === true)
    ) {
      alert("select number of pages for other");
    } else if ((updateOrder.otherTypePaperType === "") & (updateOrder.addOtherTypePages === true)) {
      alert("select paper type for other");
    } else if ((updateOrder.otherTypeGsm === "") & (updateOrder.addOtherTypePages === true)) {
      alert("select gsm for other");
    } else if (
      (updateOrder.otherTypeNumberOfColors === "") &
      (updateOrder.addOtherTypePages === true)
    ) {
      alert("select number of colors for other");
    } else {
      setShowDetails(true);
    }
  };
  const onCloseModal = () => {
    setEditUser(false);
  };
  const [staticsizes, setStaticsizes] = useState([]);
  useEffect(() => {
    getApiMathod7();
  }, []);
  const url7 = `${url}/api/sizes`;
  const getApiMathod7 = async () => {
    try {
      const response = await axios.get(url7);
      setStaticsizes(response.data.data.filter((item) => item.paperName === "MultiPageBrochure"));
    } catch (error) {
      var err2 = error.response;
      console.log(err2);
    }
  };

  const [gsmData, setGsmData] = useState([]);
  useEffect(() => {
    getGsmData();
  }, []);
  const url9 = `${url}/api/gsm`;
  const getGsmData = async () => {
    try {
      const response = await axios.get(url9);
      setGsmData(response.data.data);
    } catch (error) {
      var err2 = error.response;
      console.log(err2);
    }
  };

  const laminationsideValue = [
    { name: "Cover Single Side", q: 1, q1: 1 },
    { name: "Cover Double Side", q: 2, q1: 1 },
    {
      name: "All Double Side",
      q: 2,
      q1: updateOrder.bookInnerNumberOfPages / 2,
    },
  ];
  const printsideValue = [
    { name: "Single Side", q: 1 },
    { name: "Double Side", q: 2 },
  ];
  const gsmC = gsmData.filter((item) => item.paperName === updateOrder.paperType).map((i) => i.gsm);
  const gsmI = gsmData
    .filter((item) => item.paperName === updateOrder.bookInnerPaperType)
    .map((i) => i.gsm);
  const gsmO = gsmData
    .filter((item) => item.paperName === updateOrder.otherTypePaperType)
    .map((i) => i.gsm);

  const [bindingPrice, setBindingPrice] = useState([]);
  const [paperPrice, setPaperPrice] = useState([]);
  const [colorPrice, setColorPrice] = useState([]);

  useEffect(() => {
    getApiMathod1();
  }, []);
  const url2 = `${url}/api/paperprice`;
  const getApiMathod1 = async () => {
    try {
      const response = await axios.get(url2);

      setPaperPrice(response.data.data.filter((item) => item.forMultiPageBrochurePrinting));
    } catch (error) {
      var err2 = error.response;
      console.log(err2);
    }
  };

  useEffect(() => {
    getApiMathod3();
  }, []);
  const url4 = `${url}/api/bindingprice`;
  const getApiMathod3 = async () => {
    try {
      const response = await axios.get(url4);

      setBindingPrice(response.data.data.filter((item) => item.forMultiPageBrochure === true));
    } catch (error) {
      var err2 = error.response;
      console.log(err2);
    }
  };
  const [laminationPrice, setLaminationPrice] = useState([]);

  useEffect(() => {
    getApiMathod4();
  }, []);
  const url5 = `${url}/api/laminationprice`;
  const getApiMathod4 = async () => {
    try {
      const response = await axios.get(url5);

      setLaminationPrice(response.data.data);
    } catch (error) {
      var err2 = error.response;
      console.log(err2);
    }
  };

  useEffect(() => {
    getApiMathod2();
  }, []);
  const url3 = `${url}/api/colorprice`;
  const getApiMathod2 = async () => {
    try {
      const response = await axios.get(url3);

      setColorPrice(response.data.data);
    } catch (error) {
      var err2 = error.response;
      console.log(err2);
    }
  };

  const selectedSizeList = staticsizes.filter((item) => item.name === updateOrder.size);
  const sizes = selectedSizeList.length ? selectedSizeList[0] : [];

  const CoverPaperCost = () => {
    const selectedPaperType = paperPrice.filter((i) => i.name === updateOrder.paperType)[0];
    const selectedSize = sizes;
    const coverSheet = updateOrder.Quantity / (selectedSize.q / 2);
    const PaperCost =
      ((selectedSize.l * selectedSize.b * updateOrder.gsm) / 1550000) *
      selectedPaperType.price *
      coverSheet;

    return Math.floor(PaperCost);
  };

  const CoverPrintCost = () => {
    const selectedPrintside = printsideValue.filter((i) => i.name === updateOrder.printSide)[0];
    const selectedColor = colorPrice.filter((i) => i.name === updateOrder.numberOfcolors)[0];
    const selectedSize = sizes;
    const coverSheet = updateOrder.Quantity / (selectedSize.q / 2);
    const setQuantity = Math.ceil((selectedSize.s * selectedPrintside.q) / 2);
    const coverPrintSheet = coverSheet * selectedSize.css;
    const CoverPrintCost =
      (Math.ceil((coverPrintSheet * Math.ceil(selectedPrintside.q / selectedSize.s)) / 1000) *
        selectedColor.price +
        selectedColor.plate) *
      setQuantity;
    // const test =selectedSize.name==="A-5"?2:1
    return Math.floor(CoverPrintCost);
  };

  const InnerPaperCost = () => {
    const selectedPaperType = paperPrice.filter(
      (i) => i.name === updateOrder.bookInnerPaperType,
    )[0];
    const selectedSize = sizes;
    const InnerPaper =
      (updateOrder.bookInnerNumberOfPages / (selectedSize.q * 2)) * updateOrder.Quantity;
    const InnerPaperCost =
      [(selectedSize.l * selectedSize.b * updateOrder.bookInnerGsm) / 1550000] *
      selectedPaperType.price *
      InnerPaper;
    return Math.floor(InnerPaperCost);
  };

  const InnerPrintCost = () => {
    const selectedColor = colorPrice.filter(
      (i) => i.name === updateOrder.bookInnerNumberOfColors,
    )[0];
    const selectedSize = sizes;
    const InnerPaperSet = updateOrder.bookInnerNumberOfPages / selectedSize.iq;
    const InnerPrintCost =
      (Math.ceil(updateOrder.Quantity / 1000) * selectedColor.price + selectedColor.plate) *
      InnerPaperSet;
    return Math.floor(InnerPrintCost);
  };

  // const OtherPaperCost = () => {
  //   const selectedPaperType = paperPrice.filter((i) => i.name === updateOrder.otherTypePaperType)[0]
  //   const selectedSize = sizes
  //   const otherPaper =
  //     (updateOrder.otherTypeNumberOfPages / (selectedSize.q * 2)) * updateOrder.Quantity
  //   const otherPaperCost =
  //     [(selectedSize.l * selectedSize.b * updateOrder.otherTypeGsm) / 1550000] *
  //     selectedPaperType.price *
  //     otherPaper
  //   return Math.floor(otherPaperCost)
  // }

  // const OtherPrintCost = () => {
  //   const selectedColor = colorPrice.filter(
  //     (i) => i.name === updateOrder.otherTypeNumberOfColors,
  //   )[0]
  //   const selectedSize = sizes
  //   const OtherInnerPaperSet = updateOrder.otherTypeNumberOfPages / selectedSize.iq
  //   const OtherInnerPrintCost =
  //     (Math.ceil(updateOrder.Quantity / 1000) * selectedColor.price + selectedColor.plate) *
  //     OtherInnerPaperSet
  //   return Math.floor(OtherInnerPrintCost)
  // }

  const newLaminationCost = () => {
    const selectedSize = sizes;
    const selectedLamination = laminationPrice.filter(
      (i) => i.name === updateOrder.LaminationType,
    )[0];
    const selectedLaminationSide = laminationsideValue.filter(
      (i) => i.name === updateOrder.LaminationSide,
    )[0];
    const laminationCost =
      ((selectedSize.l * selectedSize.b * selectedLaminationSide.q) /
        (selectedSize.q / selectedSize.css)) *
        selectedLamination.price +
      ((selectedSize.l * selectedSize.b * selectedLaminationSide.q1) /
        (selectedSize.q / selectedSize.css)) *
        selectedLamination.price;
    return Math.floor(laminationCost * updateOrder.Quantity);
  };
  const newBindingCost = () => {
    const selectedBinding = bindingPrice.filter((item) => item.name === updateOrder.binding)[0];
    const selectedSize = sizes;
    const InnerPaperSet = updateOrder.bookInnerNumberOfPages / selectedSize.iq;
    const OtherInnerPaperSet = updateOrder.otherTypeNumberOfPages / selectedSize.iq;
    const bindingCost = (InnerPaperSet / 2) * selectedBinding.price + selectedBinding.fixedCost;
    return Math.floor(bindingCost * updateOrder.Quantity);
  };

  const newTotalCost = () => {
    const totalCost =
      CoverPaperCost() +
      CoverPrintCost() +
      InnerPaperCost() +
      InnerPrintCost() +
      ((updateOrder.addOtherTypePages === true) &
      (updateOrder.otherTypePaperType !== "") &
      (updateOrder.otherTypeNumberOfPages !== "") &
      (updateOrder.otherTypeGsm !== "") &
      (updateOrder.otherTypeNumberOfColors !== "")
        ? OtherPaperCost() + OtherPrintCost()
        : 0) +
      (updateOrder.binding !== "" ? newBindingCost() : 0) +
      ((updateOrder.LaminationFabricFinishing === true) &
      (updateOrder.LaminationType !== "") &
      (updateOrder.LaminationSide !== "")
        ? newLaminationCost()
        : 0);

    return Math.floor(totalCost);
  };
  const PaperBrand = () => {
    if (paperPrice.filter((i) => i.name === updateOrder.paperType).length) {
      const selectedPaperType = paperPrice.filter((i) => i.name === updateOrder.paperType)[0];
      return `(${selectedPaperType.companyName})`;
    }
  };
  const PaperBrandInner = () => {
    if (paperPrice.filter((i) => i.name === updateOrder.bookInnerPaperType).length) {
      const selectedPaperType = paperPrice.filter(
        (i) => i.name === updateOrder.bookInnerPaperType,
      )[0];
      return `(${selectedPaperType.companyName})`;
    }
  };
  const PaperBrandOther = () => {
    if (paperPrice.filter((i) => i.name === updateOrder.otherTypePaperType).length) {
      const selectedPaperType = paperPrice.filter(
        (i) => i.name === updateOrder.otherTypePaperType,
      )[0];
      return `(${selectedPaperType.companyName})`;
    }
  };
  const PaperSays = () => {
    if (staticsizes.filter((item) => item.name === updateOrder.size).length) {
      const selectedSize = staticsizes.filter((item) => item.name === updateOrder.size)[0];
      return `(${selectedSize.says})`;
    }
  };
  return (
    //
    <>
      {
        showLogin ? (
          <Login loginoff={loginoff} />
        ) : (
          //
          <>
            <div className="bg-white">
              <div className="container">
                <h2>MULTI PAGE BROCHURE PRINTING</h2>
                <div className="row mb-3">
                  <div className="col-sm-6">
                    <div className="row">
                      <div
                        id="carouselExampleIndicators"
                        className="carousel slide w-100"
                        data-ride="carousel"
                      >
                        <div className="carousel-inner" style={{ paddingTop: "1rem" }}>
                          <CarouselProvider
                            naturalSlideWidth={100}
                            naturalSlideHeight={70}
                            totalSlides={3}
                          >
                            <Slider>
                              <Slide index={0}>
                                {" "}
                                <img src={img1} className="w-100" />
                              </Slide>
                              <Slide index={1}>
                                {" "}
                                <img src={img2} className="w-100" />
                              </Slide>
                              <Slide index={2}>
                                {" "}
                                <img src={img3} className="w-100" />
                              </Slide>
                            </Slider>
                            <ButtonNext className="carousel-control-next">
                              <i class="fa fa-arrow-right fa-2x" aria-hidden="true"></i>
                            </ButtonNext>
                            <ButtonBack className="carousel-control-prev">
                              <i class="fa fa-arrow-left fa-2x" aria-hidden="true"></i>
                            </ButtonBack>
                          </CarouselProvider>
                        </div>
                        <div className="row justify-content-center mt-2">
                          <div className="col-2  ">
                            <div
                              className="row"
                              data-target="#carouselExampleIndicators"
                              data-slide-to={0}
                            >
                              <img src={img1} className="img-fluid" />
                            </div>
                          </div>
                          <div className="col-2  ">
                            <div
                              className="row"
                              data-target="#carouselExampleIndicators"
                              data-slide-to={1}
                            >
                              <img src={img2} className="img-fluid" />
                            </div>
                          </div>
                          <div className="col-2 ">
                            <div
                              className="row"
                              data-target="#carouselExampleIndicators"
                              data-slide-to={2}
                            >
                              <img src={img3} className="img-fluid" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-12">
                    <div className="m-3 p-4 bg-white justify-content-center">
                      <div
                        className="p-0 m-0 text-center bg-light text-dark my-auto"
                        style={{ borderRadius: "10px" }}
                      >
                        <h4 className=" m-0 p-2">Multi Page Brochure Calculation</h4>
                      </div>
                      <div>&nbsp;</div>
                      <form action="#">
                        <div className="row">
                          <div className="col-xl-6">
                            <div className="form-group row">
                              <label style={{ textAlign: "left" }}>Multi Page Brochure Size</label>

                              <select
                                className="form-control"
                                name="size"
                                onChange={(e) => setForm(e)}
                              >
                                <option value="" disabled selected>
                                  -Select Multi Page Brochure Size-
                                </option>
                                {staticsizes.map((item) => (
                                  <option>{item.name}</option>
                                ))}
                              </select>
                            </div>
                            <div className="form-group row mobileview">
                              <label style={{ textAlign: "left" }}>Quantity</label>
                              <input
                                placeholder="-Enter Quantity-"
                                type="text"
                                className="form-control mobileview"
                                name="Quantity"
                                onChange={(e) => setForm(e)}
                              />
                            </div>
                            <div style={{ color: "rgb(30, 115, 253)" }}>&nbsp;{PaperSays()}</div>
                            <h6
                              _ngcontent-yxf-c18=""
                              class="bg-info p-2 text-light text-center"
                              style={{ borderRadius: 20 }}
                            >
                              Cover Page Fields
                            </h6>

                            <div className="form-group row">
                              <label style={{ textAlign: "left" }}>Paper Type</label>

                              <select
                                className="form-control"
                                name="paperType"
                                onChange={(e) => setFormpt(e)}
                              >
                                <option value="" disabled selected>
                                  -Select Paper Type-
                                </option>
                                {paperPrice.map((item) => (
                                  <option>
                                    {item.name}({item.companyName})
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>&nbsp;</div>
                            <div className="form-group row">
                              <label style={{ textAlign: "left" }}>GSM *</label>

                              <select
                                className="form-control"
                                name="gsm"
                                onChange={(e) => setForm(e)}
                              >
                                <option value="" disabled selected>
                                  -Select GSM-
                                </option>
                                {gsmC.map((item) => (
                                  <option>{item}</option>
                                ))}
                              </select>
                            </div>
                            <div>&nbsp;</div>
                            <div className="form-group row">
                              <label style={{ textAlign: "left" }}>No of Colors *</label>
                              <select
                                className="form-control"
                                name="numberOfcolors"
                                onChange={(e) => setForm(e)}
                              >
                                <option value="" disabled selected>
                                  -Select No. Of Colors-
                                </option>
                                <option>Single Color</option>
                                <option>Double Color</option>
                                <option>Four Color</option>
                              </select>
                            </div>
                            <div>&nbsp;</div>

                            <div className="form-group row">
                              <label style={{ textAlign: "left" }}>Print Side *</label>
                              <select
                                className="form-control"
                                name="printSide"
                                onChange={(e) => setForm(e)}
                              >
                                <option value="" disabled selected>
                                  -Select Print Side-
                                </option>
                                <option>Single Side</option>
                                <option>Double Side</option>
                              </select>
                            </div>
                            <div>&nbsp;</div>
                          </div>
                          <div className="col-xl-6">
                            <div className="form-group row desktopview">
                              <label style={{ textAlign: "left" }}>Quantity</label>
                              <input
                                placeholder="-Enter Quantity-"
                                type="text"
                                className="form-control"
                                name="Quantity"
                                onChange={(e) => setForm(e)}
                              />
                            </div>
                            <div>&nbsp;</div>
                            <h6
                              _ngcontent-yxf-c18=""
                              class="bg-info p-2 text-light text-center"
                              style={{ borderRadius: 20 }}
                            >
                              Inner Page Fields
                            </h6>
                            <div className="form-group row">
                              <label style={{ textAlign: "left" }}>No. of Pages</label>
                              <input
                                placeholder="-Enter No. Of Pages-"
                                type="text"
                                className="form-control"
                                name="bookInnerNumberOfPages"
                                value={updateOrder.bookInnerNumberOfPages}
                                onChange={(e) => setFormp(e)}
                              />
                            </div>
                            <div>&nbsp;</div>
                            <div className="form-group row">
                              <label style={{ textAlign: "left" }}>Paper Type</label>

                              <select
                                className="form-control"
                                name="bookInnerPaperType"
                                onChange={(e) => setFormpt(e)}
                              >
                                <option value="" disabled selected>
                                  -Select Paper Type-
                                </option>
                                {paperPrice.map((item) => (
                                  <option>
                                    {item.name}({item.companyName})
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div>&nbsp;</div>
                            <div className="form-group row">
                              <label style={{ textAlign: "left" }}>GSM *</label>

                              <select
                                className="form-control"
                                name="bookInnerGsm"
                                onChange={(e) => setForm(e)}
                              >
                                <option value="" disabled selected>
                                  -Select GSM-
                                </option>
                                {gsmI.map((item) => (
                                  <option>{item}</option>
                                ))}
                              </select>
                            </div>
                            <div>&nbsp;</div>
                            <div className="form-group row">
                              <label style={{ textAlign: "left" }}>No of Colors *</label>
                              <select
                                className="form-control"
                                name="bookInnerNumberOfColors"
                                onChange={(e) => setForm(e)}
                              >
                                <option value="" disabled selected>
                                  -Select No. Of Colors-
                                </option>
                                <option>Single Color</option>
                                <option>Double Color</option>
                                <option>Four Color</option>
                              </select>
                            </div>
                            <div>&nbsp;</div>
                          </div>
                          <div>
                            <label style={{ textAlign: "left" }}>Binding *</label>

                            <select
                              className="form-control"
                              name="binding"
                              onChange={(e) => setForm(e)}
                            >
                              <option value="" disabled selected>
                                -Select Binding
                              </option>
                              {bindingPrice.map((item) => (
                                <option>{item.name}</option>
                              ))}
                            </select>
                          </div>
                          <div>&nbsp;</div>
                          {/* <div className="col-xl-6">
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="exampleCheck1"
                          name="addOtherTypePages"
                          onChange={(e) => setForm(e)}
                        />
                        <label style={{ textAlign: 'left', marginLeft: '5%' }}>
                          Add Other Type Pages
                        </label>
                      </div>
                      {updateOrder.addOtherTypePages === true ? (
                        <div>
                          <div>
                            <div className="form-group row">
                              <label style={{ textAlign: 'left' }}>No. of Pages</label>
                              <input
                                placeholder="-Enter No Of Pages-"
                                type="text"
                                className="form-control"
                                name="otherTypeNumberOfPages"
                                value={updateOrder.otherTypeNumberOfPages}
                                onChange={(e) => setFormp(e)}
                              />
                            </div>
                            <div>&nbsp;</div>
                          </div>
                          <div>
                            <div className="form-group row">
                              <label style={{ textAlign: 'left' }}>Paper Type</label>

                              <select
                                className="form-control"
                                name="otherTypePaperType"
                                onChange={(e) => setFormpt(e)}
                              >
                                <option value="" disabled selected>
                                  -Select Paper Type-
                                </option>
                                {paperPrice.map((item) => (
                                  <option>
                                    {item.name}({item.companyName})
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>&nbsp;</div>
                          </div>
                          <div>
                            <div className="form-group row">
                              <label style={{ textAlign: 'left' }}>GSM *</label>

                              <select
                                className="form-control"
                                name="otherTypeGsm"
                                onChange={(e) => setForm(e)}
                              >
                                <option value="" disabled selected>
                                  -Select GSM-
                                </option>
                                {gsmO.map((item) => (
                                  <option>{item}</option>
                                ))}
                              </select>
                            </div>
                            <div>&nbsp;</div>
                          </div>
                          <div>
                            <div className="form-group row">
                              <label style={{ textAlign: 'left' }}>No of Colors *</label>
                              <select
                                className="form-control"
                                name="otherTypeNumberOfColors"
                                onChange={(e) => setForm(e)}
                              >
                                <option value="" disabled selected>
                                  -Select No Of Colors-
                                </option>
                                <option>Single Color</option>
                                <option>Double Color</option>
                                <option>Four Color</option>
                              </select>
                            </div>

                            <div>&nbsp;</div>
                          </div>
                        </div>
                      ) : null}
                    </div> */}
                          <div className="col-xl-6">
                            <div style={{ display: "flex", flexDirection: "row" }}>
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="exampleCheck1"
                                name="LaminationFabricFinishing"
                                onChange={(e) => setForm(e)}
                              />
                              <label style={{ textAlign: "left", marginLeft: "5%" }}>
                                Lamination & Finishing
                              </label>
                            </div>
                            {updateOrder.LaminationFabricFinishing === true ? (
                              <div>
                                <div>
                                  <div className="form-group row">
                                    <label style={{ textAlign: "left" }}>Lamination</label>

                                    <select
                                      className="form-control"
                                      name="LaminationType"
                                      onChange={(e) => setForm(e)}
                                    >
                                      <option value="" disabled selected>
                                        -Select Lamination-
                                      </option>
                                      {laminationPrice.map((item) => (
                                        <option>{item.name}</option>
                                      ))}
                                    </select>
                                  </div>
                                  <div>&nbsp;</div>
                                </div>
                                <div>
                                  <div className="form-group row">
                                    <label style={{ textAlign: "left" }}>Lamination Side*</label>

                                    <select
                                      className="form-control"
                                      name="LaminationSide"
                                      onChange={(e) => setForm(e)}
                                    >
                                      <option value="" disabled selected>
                                        -Select Lamination Side-
                                      </option>
                                      {laminationsideValue.map((item) => (
                                        <option>{item.name}</option>
                                      ))}
                                    </select>
                                  </div>

                                  <div>&nbsp;</div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="text-center mt-3">
                            <button
                              type="button"
                              className="btn btn-primary btn-lg"
                              style={{
                                paddingLeft: "5.5rem",
                                paddingRight: "5.5rem",
                              }}
                              onClick={() => showDetailsHandler()}
                            >
                              Calculate Price
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showDetails === true ? (
              <div className="container" style={{ paddingBottom: "2%" }}>
                <div className="row">
                  <div className="col-sm-6 mb-3">
                    <div className="card">
                      <div
                        className="card-header"
                        style={{
                          backgroundColor: "rgb(6,120,139)",
                          color: "white",
                        }}
                      >
                        <p className="lead fw-bold ">Multi Page Brochure Printing</p>
                        <div className="row">
                          <div className="col-sm-8">Specification</div>
                          <div className="col-sm-4" align="center">
                            Quantity
                          </div>
                        </div>
                      </div>
                      <div
                        className="card-body"
                        style={{ backgroundColor: "azure" }}
                        mat-typography
                      >
                        <div className="row">
                          <div className="col-sm-8">
                            <p style={{ textAlign: "left" }}>
                              <b> Multi Page Brochure Size</b> : {updateOrder.size}
                            </p>
                            <p style={{ textAlign: "left" }}>
                              <b>Cover Page </b>: {updateOrder.gsm} gsm on {updateOrder.paperType}{" "}
                              paper {updateOrder.numberOfcolors} {updateOrder.printSide} printing
                            </p>
                            <p style={{ textAlign: "left" }}>
                              <b>Inner Page </b>: {updateOrder.bookInnerNumberOfPages} Pages{" "}
                              {updateOrder.bookInnerGsm} gsm {updateOrder.bookInnerPaperType} paper{" "}
                              {updateOrder.bookInnerNumberOfColors} {updateOrder.bookInnerPrintSide}{" "}
                              printing
                            </p>
                            <p style={{ textAlign: "left" }}>
                              <b>Binding </b>: {updateOrder.binding} printing
                            </p>
                            {/* <p style={{ textAlign: 'left' }}>
                        <b>Other Inner Page </b>:{updateOrder.otherTypeNumberOfPages} Pages{' '}
                        {updateOrder.otherTypeGsm} gsm {updateOrder.otherTypePaperType} paper
                        {updateOrder.otherTypeNumberOfColors} {updateOrder.otherTypePrintSide}{' '}
                        printing
                      </p> */}
                            {updateOrder.LaminationSide !== "" ? (
                              <p style={{ textAlign: "left" }}>
                                <b>Finishing </b>: {updateOrder.LaminationSide}{" "}
                                {updateOrder.LaminationType}
                              </p>
                            ) : null}
                          </div>
                          <div className="col-sm-4" align="center">
                            <h5> {updateOrder.Quantity}</h5>
                          </div>
                        </div>
                      </div>
                      <div
                        className="card-footer"
                        style={{
                          backgroundColor: "rgb(6,120,139)",
                          color: "white",
                        }}
                      >
                        {/* <h6 align="end">Cover Paper Cost : {CoverPaperCost()} </h6>
                  <h6 align="end">Cover Print Cost : {CoverPrintCost()} </h6>
                  <h6 align="end">Inner Paper Cost : {InnerPaperCost()} </h6>
                  <h6 align="end">Inner Print Cost : {InnerPrintCost()} </h6>
                  {(updateOrder.addOtherTypePages === true) &
                  (updateOrder.otherTypePaperType !== '') &
                  (updateOrder.otherTypeNumberOfPages !== '') &
                  (updateOrder.otherTypeGsm !== '') &
                  (updateOrder.otherTypeNumberOfColors !== '') ? (
                    <h6 align="end">Other Paper Cost : {OtherPaperCost()} </h6>
                  ) : null}
                  {(updateOrder.addOtherTypePages === true) &
                  (updateOrder.otherTypePaperType !== '') &
                  (updateOrder.otherTypeNumberOfPages !== '') &
                  (updateOrder.otherTypeGsm !== '') &
                  (updateOrder.otherTypeNumberOfColors !== '') ? (
                    <h6 align="end">Other Print Cost : {OtherPrintCost()} </h6>
                  ) : null}
                  {updateOrder.binding !== '' ? (
                    <h6 align="end">Binding Cost : {newBindingCost()} </h6>
                  ) : null}
                  {(updateOrder.LaminationFabricFinishing === true) &
                  (updateOrder.LaminationType !== '') &
                  (updateOrder.LaminationSide !== '') ? (
                    <h6 align="end">Lamination Cost : {newLaminationCost()} </h6>
                  ) : null} */}
                        <h5 align="center">Total Price : {newTotalCost()} </h5>
                      </div>
                    </div>
                    <div style={{ paddingTop: "2%" }}>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => SubmitOrderHandler()}
                      >
                        Add To Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {showCart ? <Cart goToHome={goToHome} /> : null}

            <Modal isOpen={editUser} toggle={onCloseModal} classNames="">
              <ModalBody>
                <div class="container">
                  <div style={{ textAlign: "center" }}>If you are already a member !</div>
                  <div
                    class="row"
                    style={{
                      marginLeft: "20%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <button
                      onClick={() => setShowLogin(true)}
                      style={{
                        width: "27%",
                        border: "none",
                        background: "#0d6efd",
                        borderRadius: 5,
                        color: "white",
                      }}
                    >
                      Login
                    </button>

                    <button
                      onClick={() => setShowLogin(true)}
                      style={{
                        width: "27%",
                        marginLeft: "20%",
                        border: "none",
                        background: "#0d6efd",
                        borderRadius: 5,
                        color: "white",
                      }}
                    >
                      SignUp
                    </button>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </>
        )
        //
      }
    </>
    //
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.orders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeorders: (orders) => {
      dispatch(changeOrders(orders));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MultiPageBrochure);
