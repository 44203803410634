import React, { useState } from "react";

const Contactus = (props) => {
  return (
    <>
      <section id="contact">
        <div className="container">
          <h3 className="text-center text-uppercase">contact us</h3>
          <p className="text-center w-75 m-auto">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris interdum purus at sem
            ornare sodales. Morbi leo nulla, pharetra vel felis nec, ullamcorper condimentum quam.
          </p>
          <div className="row">
            <div className="col-sm-12 col-md-1 col-lg-4 my-5">
              <div className="card border-0">
                <div className="card-body text-center">
                  <i
                    className="fa fa-phone fa-5x mb-3"
                    style={{ color: "rgb(66,66,146)" }}
                    aria-hidden="true"
                  />
                  <h4 className="text-uppercase mb-5">call us</h4>
                  <p>&nbsp; +91-7880003671</p>
                  <p>&nbsp; +91-7691986246</p>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-4 my-5">
              <div className="card border-0">
                <div className="card-body text-center">
                  <i
                    className="fa fa-map-marker fa-5x mb-3"
                    style={{ color: "rgb(66,66,146)" }}
                    aria-hidden="true"
                  />
                  <h4 className="text-uppercase mb-5">office location</h4>
                  <address>Plot No 210 , Zone-1 </address>
                  <address>M.P Nagar, Bhopal - 462011 </address>
                  <address>(M.P) India </address>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 my-5">
              <div className="card border-0">
                <div className="card-body text-center">
                  <i
                    className="fa fa-globe fa-5x mb-3"
                    style={{ color: "rgb(66,66,146)" }}
                    aria-hidden="true"
                  />
                  <h4 className="text-uppercase mb-5">email</h4>
                  <p>&nbsp;info@printbajar.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contactus;
