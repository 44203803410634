import React, { useState, useEffect } from "react";
import axios from "axios";
import { url } from "./helper";
import { Modal } from "reactstrap";
import { connect } from "react-redux";
import { changeOrders } from "../actions/action";
import yourhandle from "countrycitystatejson";
import cdr from "./cdr.png";
import storage from "./Firebase";
import dotted from "./dotted.png";
import { mobile } from "fontawesome";

const CartList = (props) => {
  const token = localStorage.getItem("token");
  const isLoggedIn = token ? true : false;

  const orderId = props.orders.length > 0 ? "a" : null;
  const isOrder = orderId !== null ? true : false;

  const userId = localStorage.getItem("businessid");
  const [addressData, setAddressData] = useState([]);

  useEffect(async () => {
    getAddress();
  }, []);

  const getAddress = async () => {
    if (isLoggedIn) {
      await axios.get(`${url}/api/billingaddress/user/${userId}`).then((response) => {
        setAddressData(response.data.data);
        setBillingAddress(response.data.data[0]._id);
      });
    }
  };
  const [editUser, setEditUser] = useState(false);
  const [editUserAddress, setEditUserAddress] = useState(false);
  const [addExtraImagesOpen, setAddExtraImagesOpen] = useState(false);
  const onCloseModal = () => {
    setEditUser(false);
    setEditUserAddress(false);
    setAddExtraImagesOpen(false);
  };

  const [billingAddress, setBillingAddress] = useState("");
  const addAddressInOrder = () => {
    if (isLoggedIn) {
      const orders = props.orders;

      const list = orders.filter((i) => i.design1 === "" || i.design2 === "" || i.cdr === "");
      if (billingAddress === "") {
        alert("Please Select Billing Address");
      } else if (image1 === "") {
        alert("Please Select Image");
      } else if (list.length > 0) {
        alert("Please Select All Images and cdr");
      } else {
        orders.map((order, index) =>
          axios
            .put(`${url}/api/order/billingaddress/${order._id}`, {
              billingAddress: billingAddress,
              design1: order.design1,
              design2: order.design2,
              cdr: order.cdr,
            })
            .then(() => {
              if (orders.length === index + 1) {
                alert("Order Placed Successfully");
                props.changeorders([]);
              }
            }),
        );
      }
    } else {
      alert("No Item Is Selected");
    }
  };
  const uploadFrontImageToOrder = (x, y) => {
    if (isLoggedIn) {
      const item = {
        design1: y,
      };
      axios
        .put(`${url}/api/order/billingaddress/${x}`, item)
        .then((res) => {
          const list = props.orders.map((i) =>
            i._id === res.data.data._id ? { ...i, design1: res.data.data.design1 } : i,
          );
          props.changeorders(list);
        })
        .then(() => {
          setProgress1(0);
          alert("Image Uploaded Successfully");
        });
    } else {
      alert("No Item Is Selected");
    }
  };
  const uploadBackImageToOrder = (x, y) => {
    if (isLoggedIn) {
      const item = {
        design2: y,
      };
      axios
        .put(`${url}/api/order/billingaddress/${x}`, item)
        .then((res) => {
          const list = props.orders.map((i) =>
            i._id === res.data.data._id ? { ...i, design2: res.data.data.design2 } : i,
          );
          props.changeorders(list);
        })
        .then(() => {
          setProgress2(0);
          alert("Image Uploaded Successfully");
        });
    } else {
      alert("No Item Is Selected");
    }
  };
  const uploadCdrToOrder = (x, y) => {
    if (isLoggedIn) {
      const item = {
        cdr: y,
      };
      axios
        .put(`${url}/api/order/billingaddress/${x}`, item)
        .then((res) => {
          const list = props.orders.map((i) =>
            i._id === res.data.data._id ? { ...i, cdr: res.data.data.cdr } : i,
          );
          props.changeorders(list);
        })
        .then(() => {
          setProgress3(0);
          alert("File Uploaded Successfully");
        });
    } else {
      alert("No Item Is Selected");
    }
  };
  const [orderIdForImage, setOrderIdForImage] = useState("");
  const uploadExtraImageToOrder = (x) => {
    if (isLoggedIn) {
      const item = {
        orderId: orderIdForImage,
        tital: tital,
        image: x,
      };
      axios
        .post(`${url}/api/image`, item)
        .then((res) => getImageData())
        .then(() => {
          setProgress(0);
          alert("Image Uploaded Successfully");
        });
    } else {
      alert("No Item Is Selected");
    }
  };

  const [updateAddress, setUpdateAddress] = useState({
    userId: userId,
    addressType: "",
    name: "",
    email: "",
    mobile: "",
    address: "",
    landmark: "",
    state: "",
    city: "",
    pincode: "",
  });
  const [updateAddressEdit, setUpdateAddressEdit] = useState({
    userId: userId,
    addressType: "",
    name: "",
    email: "",
    mobile: "",
    address: "",
    landmark: "",
    state: "",
    city: "",
    pincode: "",
  });
  const setForm = (event) => {
    let { name, value } = event.target;

    setUpdateAddress({
      ...updateAddress,
      [name]: value,
    });
  };
  const setForm2 = (event) => {
    let { name, value } = event.target;

    setUpdateAddressEdit({
      ...updateAddressEdit,
      [name]: value,
    });
  };
  const isValidEmail = (mail) => {
    return /\S+@\S+\.\S+/.test(mail);
  };

  const validatePhone = (input_str) => {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

    return re.test(input_str);
  };

  const SubmitNewAddress = async () => {
    if (updateAddress.name === "") {
      alert("Enter Name");
    } else if (updateAddress.email === "") {
      alert("Enter Email");
    } else if (updateAddress.mobile === "") {
      alert("Enter Mobile");
    } else if (updateAddress.address === "") {
      alert("Enter Address");
    } else if (updateAddress.addressType === "") {
      alert("Select Address Type");
    } else if (updateAddress.landmark === "") {
      alert("Enter Landmark");
    } else if (updateAddress.state === "") {
      alert("Select State");
    } else if (updateAddress.city === "") {
      alert("Select City");
    } else if (updateAddress.pincode === "") {
      alert("Enter Pincode");
    } else if (isValidEmail(updateAddress.email) === false) {
      alert("Invalid Email");
    } else if (validatePhone(updateAddress.mobile) === false) {
      alert("Invalid Phone Number");
    } else {
      await axios.post(`${url}/api/billingaddress`, updateAddress).then((response) => {
        alert("new address added");
        getAddress();
        onCloseModal();
      });
    }
  };
  const SubmitEditAddress = async () => {
    await axios
      .put(`${url}/api/billingaddress/${updateAddressEdit._id}`, updateAddressEdit)
      .then((response) => {
        alert("Address Updated");
        getAddress();
        onCloseModal();
      });
  };
  const DeleteAddress = async (x) => {
    await axios.delete(`${url}/api/billingaddress/${x}`).then(() => {
      getAddress();
    });
  };
  const [gstData, setGstData] = useState([]);
  const gst = (x) => {
    if (gstData.filter((i) => i.product === x).length > 0) {
      const gst = gstData.filter((i) => i.product === x)[0].gst;
      return gst;
    } else {
      return 0;
    }
  };
  useEffect(async () => {
    getGst();
  }, []);

  const getGst = async () => {
    if (isOrder) {
      await axios.get(`${url}/api/gst`).then((response) => {
        setGstData(response.data.data);
      });
    }
  };
  const [image1, setImage1] = useState("");
  const uploadImage1 = (id) => {
    if (image1 !== "") {
      const uploadTask = storage.ref(`images/${image1.name}`).put(image1);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setProgress1(Math.ceil((snapshot.bytesTransferred / snapshot.totalBytes) * 100));
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(image1.name)
            .getDownloadURL()
            .then((url) => uploadFrontImageToOrder(id, url));
        },
      );
      // const data = new FormData()
      // data.append('file', image1)
      // data.append('upload_preset', 'printbajar')
      // data.append('cloud_name', 'mssquare')
      // fetch('  https://api.cloudinary.com/v1_1/mssquare/image/upload', {
      //   method: 'post',
      //   body: data,
      // })
      //   .then((resp) => resp.json())
      //   .then((data) => {
      //     uploadFrontImageToOrder(id, data.url)
      //   })

      //   .catch((err) => console.log(err))
    } else {
      alert("no image selected");
    }
  };
  const [image2, setImage2] = useState("");
  const uploadImage2 = (id) => {
    if (image2 !== "") {
      const uploadTask = storage.ref(`images/${image2.name}`).put(image2);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setProgress2(Math.ceil((snapshot.bytesTransferred / snapshot.totalBytes) * 100));
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(image2.name)
            .getDownloadURL()
            .then((url) => uploadBackImageToOrder(id, url));
        },
      );
      // const data = new FormData()
      // data.append('file', image2)
      // data.append('upload_preset', 'printbajar')
      // data.append('cloud_name', 'mssquare')
      // fetch('  https://api.cloudinary.com/v1_1/mssquare/image/upload', {
      //   method: 'post',
      //   body: data,
      // })
      //   .then((resp) => resp.json())
      //   .then((data) => {
      //     uploadBackImageToOrder(id, data.url)
      //   })
      //   .catch((err) => console.log(err))
    } else {
      alert("no image selected");
    }
  };

  const [image4, setImage4] = useState("");
  const uploadImage4 = (id) => {
    if (image4 !== "") {
      const uploadTask = storage.ref(`images/${image4.name}`).put(image4);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setProgress3(Math.ceil((snapshot.bytesTransferred / snapshot.totalBytes) * 100));
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(image4.name)
            .getDownloadURL()
            .then((url) => uploadCdrToOrder(id, url));
        },
      );
      // const data = new FormData()
      // data.append('file', image4)
      // data.append('upload_preset', 'printbajar')
      // data.append('cloud_name', 'mssquare')
      // fetch('  https://api.cloudinary.com/v1_1/mssquare/image/upload', {
      //   method: 'post',
      //   body: data,
      // })
      //   .then((resp) => resp.json())
      //   .then((data) => {
      //     uploadCdrToOrder(id, data.url)
      //   })
      //   .catch((err) => console.log(err))
    } else {
      alert("no file selected");
    }
  };

  const totalAmountForOrders = () => {
    if (props.orders.length) {
      const priceList = props.orders.map((i) => (i.totalPrice * (100 + gst(i.orderFor))) / 100);
      const total = priceList.reduce((a, c) => a + c);
      return total;
    } else {
      return 0;
    }
  };
  const editModalOpen = (x) => {
    setUpdateAddressEdit(x);
    setEditUserAddress(true);
  };
  const removeItem = (x) => {
    const list = props.orders.filter((i) => i._id !== x);
    props.changeorders(list);
  };
  const removeFront = (x) => {
    const item = {
      design1: "",
    };
    axios
      .put(`${url}/api/order/billingaddress/${x}`, item)
      .then((res) => {
        const list = props.orders.map((i) => (i._id === x ? { ...i, design1: "" } : i));
        props.changeorders(list);
      })
      .then(() => alert("Image Removed Successfully"));
  };
  const removeBack = (x) => {
    const item = {
      design2: "",
    };
    axios
      .put(`${url}/api/order/billingaddress/${x}`, item)
      .then((res) => {
        const list = props.orders.map((i) => (i._id === x ? { ...i, design2: "" } : i));
        props.changeorders(list);
      })
      .then(() => alert("Image Removed Successfully"));
  };
  const removeCdr = (x) => {
    const item = {
      cdr: "",
    };
    axios
      .put(`${url}/api/order/billingaddress/${x}`, item)
      .then((res) => {
        const list = props.orders.map((i) => (i._id === x ? { ...i, cdr: "" } : i));
        props.changeorders(list);
      })
      .then(() => alert("File Removed Successfully"));
  };
  const removeImage = (x) => {
    axios
      .delete(`${url}/api/image/${x}`)
      .then((res) => getImageData())
      .then(() => alert("Image Removed Successfully"));
  };

  const [imageData, setImageData] = useState([
    { image: "image1", orderId: 0, tital: "tital1" },
    { image: "image2", orderId: 0, tital: "tital2" },
    { image: "image3", orderId: 0, tital: "tital3" },
    { image: "image1", orderId: 1, tital: "tital1" },
    { image: "image2", orderId: 1, tital: "tital1" },
    { image: "image3", orderId: 1, tital: "tital1" },
  ]);
  useEffect(async () => {
    getImageData();
  }, []);

  const getImageData = async () => {
    if (isOrder) {
      await axios.get(`${url}/api/image`).then((response) => {
        setImageData(response.data.data);
      });
    }
  };

  const [image3, setImage3] = useState("");
  const [tital, setTital] = useState("");

  const uploadImage3 = () => {
    setAddExtraImagesOpen(false);
    if (image3 !== "") {
      const uploadTask = storage.ref(`images/${image3.name}`).put(image3);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setProgress(Math.ceil((snapshot.bytesTransferred / snapshot.totalBytes) * 100));
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(image3.name)
            .getDownloadURL()
            .then((url) => uploadExtraImageToOrder(url));
        },
      );
      // const data = new FormData()
      // data.append('file', image3)
      // data.append('upload_preset', 'printbajar')
      // data.append('cloud_name', 'mssquare')
      // fetch('  https://api.cloudinary.com/v1_1/mssquare/image/upload', {
      //   method: 'post',
      //   body: data,
      // })
      //   .then((resp) => resp.json())
      //   .then((data) => {
      //     uploadExtraImageToOrder(data.url)
      //   })

      //   .catch((err) => console.log(err))
    } else {
      alert("no image selected");
    }
  };
  const getStateList = () => {
    const list = yourhandle.getStatesByShort("IN");

    return list.map((i) => <option>{i}</option>);
  };
  const getCityList = () => {
    const list = yourhandle.getCities("IN", updateAddress.state);
    return list.map((i) => <option>{i}</option>);
  };
  const getCityList2 = () => {
    const list = yourhandle.getCities("IN", updateAddressEdit.state);
    return list.map((i) => <option>{i}</option>);
  };

  const [progress, setProgress] = useState("");
  const [progress1, setProgress1] = useState("");
  const [progress2, setProgress2] = useState("");
  const [progress3, setProgress3] = useState("");
  return (
    <>
      <div className="container">
        <h2 className="my-4">You have Selected {props.orders.length} Item </h2>
        {props.orders.map((customerData, index) => (
          <div className="row mb-4">
            <div className="col-sm-8" style={{ paddingLeft: "6%" }}>
              <div className="card shadow-sm ">
                <div className="row ">
                  <div className="col-md-4 col-sm-6 ">
                    <div className="card-body">
                      {customerData.design1 !== "" ? (
                        <i
                          onClick={() => removeFront(customerData._id)}
                          className="fa fa-times fa-2x"
                          style={{ float: "right", color: "rgb(84,84,156)" }}
                        ></i>
                      ) : (
                        true
                      )}
                      <h6 className="text-center">Front Side</h6>

                      <img
                        src={customerData.design1 !== "" ? customerData.design1 : dotted}
                        className="imgbody"
                      />
                      <input
                        className="m-1 w-100"
                        type="file"
                        onChange={(e) => setImage1(e.target.files[0])}
                      />
                      <button
                        onClick={() => uploadImage1(customerData._id)}
                        className="w-100 btn uploadbtn"
                      >
                        <i className="fa fa-upload " aria-hidden="true" />
                        &nbsp; Upload <i aria-hidden="true" />
                      </button>
                      <div class="progress ">
                        <div class="progress-bar" style={{ width: `${progress1}%` }}></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6">
                    <div className="card-body">
                      {customerData.design2 !== "" ? (
                        <i
                          onClick={() => removeBack(customerData._id)}
                          className="fa fa-times fa-2x "
                          style={{ float: "right", color: "rgb(84,84,156)" }}
                        ></i>
                      ) : null}
                      <h6 className="text-center">Back Side</h6>

                      <img
                        src={customerData.design2 !== "" ? customerData.design2 : dotted}
                        className="imgbody"
                      />
                      <input
                        className="m-1 w-100"
                        type="file"
                        onChange={(e) => setImage2(e.target.files[0])}
                      />

                      <button
                        onClick={() => uploadImage2(customerData._id)}
                        className="w-100 btn uploadbtn"
                      >
                        <i className="fa fa-upload " aria-hidden="true" />
                        &nbsp;Upload <i aria-hidden="true" />
                      </button>
                      <div class="progress ">
                        <div class="progress-bar" style={{ width: `${progress2}%` }}></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6">
                    <div className="card-body">
                      {customerData.cdr !== "" ? (
                        <i
                          onClick={() => removeCdr(customerData._id)}
                          className="fa fa-times fa-2x "
                          style={{ float: "right", color: "rgb(84,84,156)" }}
                        ></i>
                      ) : null}
                      <h6 className="text-center">Coral Design</h6>

                      <img src={customerData.cdr !== "" ? cdr : dotted} className="imgbody" />
                      <input
                        className="m-1 w-100"
                        type="file"
                        onChange={(e) => setImage4(e.target.files[0])}
                      />

                      <button
                        onClick={() => uploadImage4(customerData._id)}
                        className="w-100 btn uploadbtn"
                      >
                        <i className="fa fa-upload " aria-hidden="true" />
                        &nbsp;Upload <i aria-hidden="true" />
                      </button>
                      <div class="progress ">
                        <div class="progress-bar" style={{ width: `${progress3}%` }}></div>
                      </div>
                    </div>
                  </div>

                  {imageData
                    .filter((i) => i.orderId === customerData._id)
                    .map((j) => (
                      <>
                        <div className="col-md-4 col-sm-6 ">
                          <div className="card-body">
                            <i
                              onClick={() => removeImage(j._id)}
                              className="fa fa-times fa-2x "
                              style={{
                                float: "right",
                                color: "rgb(84,84,156)",
                              }}
                            ></i>
                            <h6 className="text-center">{j.tital}</h6>
                            <img src={j.image !== "" ? j.image : dotted} className="imgbody" />
                          </div>
                        </div>
                      </>
                    ))}
                </div>
                <div
                  className="card card1 uploadbtn"
                  style={{ outline: "none" }}
                  onClick={() => {
                    setOrderIdForImage(customerData._id);
                    setAddExtraImagesOpen(true);
                  }}
                >
                  <div class="progress ">
                    {/* <div
                      class={`progress-bar ${
                        progress < 25
                          ? 'bg-danger'
                          : progress < 50
                          ? 'bg-warning'
                          : progress < 75
                          ? ''
                          : progress < 100
                          ? 'bg-info'
                          : 'bg-success'
                      } progress-bar-striped progress-bar-animated`} */}
                    <div class="progress-bar" style={{ width: `${progress}%` }}></div>
                  </div>
                  <div className="row ">
                    <div className="col-sm-2">
                      <div className="card-body px-auto">
                        <i
                          className="fa fa-upload"
                          style={{ fontSize: "1.5rem" }}
                          aria-hidden="true"
                        />
                      </div>
                    </div>

                    <div className="col-sm-10">
                      <div className="card-body">
                        <h5 className="card-title cardmargin">Upload More Design</h5>
                      </div>
                    </div>
                  </div>
                </div>
                *Coral file is Necessary for printing
              </div>
            </div>
            <div className="col-sm-4 " style={{ paddingRight: "4%" }}>
              <div className="card shadow-sm">
                <div className="card-header pb-0 mb-0">
                  <i
                    onClick={() => removeItem(customerData._id)}
                    className="fa fa-times fa-2x "
                    style={{ float: "right", color: "rgb(84,84,156)" }}
                  ></i>
                  <div className="row">
                    <div className="col-sm-6  col-6">
                      <h6>Item</h6>
                    </div>
                    <div className="col-sm-6  col-6" align="end">
                      <h6>Amount</h6>
                    </div>
                  </div>
                </div>
                <div className="card-body" style={{ paddingTop: "7%" }}>
                  <div className="row">
                    <h4>{customerData.orderFor}</h4>
                    <br />
                    <p className="card-text">
                      Size : {customerData.size} | GSM : {customerData.gsm} gsm <br /> Paper :{" "}
                      {customerData.paperType} Paper
                      <br /> Color : {customerData.numberOfcolors} <br />{" "}
                      {customerData.binding ? `Binding : ${customerData.binding}` : ""}
                      {customerData.paperSide ? `| Side : ${customerData.paperSide}` : ""}
                    </p>
                    <p></p>
                    {customerData.LaminationFabricFinishing ? (
                      <p className="card-text">
                        Finishing : {customerData.LaminationType}{" "}
                        {customerData.LaminationSide
                          ? `| Side : ${customerData.LaminationSide}`
                          : ""}
                      </p>
                    ) : null}
                    {customerData.tapping ? (
                      <p className="card-text">Gumming : {customerData.gumming}</p>
                    ) : null}
                  </div>{" "}
                  {/* <div className="row">
                    <div className="col-sm-6  col-6">
                      <h5>Paper Price</h5>
                      <h5>Printing Price</h5>
                      {customerData.LaminationFabricFinishing ? <h5>Lamination Price</h5> : null}
                      {customerData.tapping ? <h5>Gumming Price</h5> : null}
                    </div>
                    <div className="col-sm-6  col-6" align="end">
                      <h5>{customerData.paperPrice}</h5>
                      <h5>{customerData.printingPrice}</h5>

                      {customerData.LaminationFabricFinishing ? (
                        <h5>{customerData.laminationPrice}</h5>
                      ) : null}
                      {customerData.tapping ? <h5>{customerData.tappingPrice}</h5> : null}
                    </div>
                  </div> */}
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col-sm-6  col-6">
                      <h6>Total Price</h6>
                      GST @ {gst(customerData.orderFor)}%
                    </div>
                    <div className="col-sm-6  col-6" align="end">
                      <h6>{customerData.totalPrice}</h6>
                      {(customerData.totalPrice * gst(customerData.orderFor)) / 100}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6 col-6">
                      <h6>Total Amount</h6>
                    </div>
                    <div className="col-sm-6 col-6" align="end">
                      <h6>
                        {Math.ceil(
                          (customerData.totalPrice * (100 + gst(customerData.orderFor))) / 100,
                        )}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div style={{ paddingLeft: "66%" }}>
          {props.orders.length > 0 ? (
            <h5 className=" ">
              Total Amount For This Order = {Math.ceil(totalAmountForOrders())}{" "}
            </h5>
          ) : null}
        </div>

        <div>
          <div style={{ marginBottom: 20 }}>
            <span style={{ marginBottom: 20 }}> Select Billing Address</span>
          </div>
          <div class="grid">
            {addressData.map((item, index) => (
              <label class="card">
                <input
                  name="plan"
                  class="radio"
                  type="radio"
                  value={item._id}
                  onChange={(e) => setBillingAddress(e.target.value)}
                  checked={item._id === billingAddress ? true : false}
                />

                <span class="plan-details">
                  <span class="plan-type">
                    {index + 1}. {item.addressType}
                  </span>

                  <span>
                    {item.address}, {item.landmark}, {item.city}, {item.state}, {item.pincode}
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "41%",
                    }}
                  >
                    <i
                      className="fa fa-edit m-1"
                      style={{ color: "rgb(84,84,156)" }}
                      onClick={() => editModalOpen(item)}
                    ></i>
                    <i
                      className="fa fa-trash m-1"
                      style={{ color: "rgb(84,84,156)" }}
                      onClick={() => DeleteAddress(item._id)}
                    ></i>
                  </div>
                </span>
              </label>
            ))}
          </div>
        </div>
        <div style={{ paddingBottom: "2%", paddingTop: "2%" }}>
          <button
            className="btn btn-sm btn-primary uploadbtn"
            onClick={() => {
              if (isLoggedIn) {
                setEditUser(true);
              } else alert("Please Login To Add Address");
            }}
          >
            Add New Address
          </button>
        </div>
        <div className="card-body ">
          <hr className="mt-0 pt-0" />
        </div>
        <div style={{ paddingBottom: "2%" }}>
          {props.orders.length > 0 ? (
            <button
              className="btn btn-sm btn-primary uploadbtn"
              onClick={() => addAddressInOrder()}
            >
              Place Order
            </button>
          ) : null}
        </div>
      </div>
      <Modal
        style={{ maxWidth: "1000px", width: "100%" }}
        isOpen={editUser}
        toggle={onCloseModal}
        classNames="popupModalRegisterUser"
      >
        <div style={{ width: "100%" }}>
          <div>
            <div className="page-wrapper  ">
              <div className="card-header">
                <h1 className="card-title mb-0" style={{ textAlign: "center" }}>
                  Add Billing Address
                </h1>
                <div className="content container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body" style={{ backgroundColor: "white" }}>
                          <h4 className="card-title"></h4>
                          <form action="#">
                            <div className="row">
                              <div className="col-xl-6">
                                <div className="form-group row ">
                                  <label className="col-lg-3 col-form-label">Name</label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="name"
                                      onChange={(e) => setForm(e)}
                                    />
                                  </div>
                                </div>
                                <div>&nbsp;</div>

                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">Email</label>
                                  <div className="col-lg-9">
                                    <input
                                      type="email"
                                      className="form-control"
                                      name="email"
                                      onChange={(e) => setForm(e)}
                                    />
                                  </div>
                                </div>
                                <div>&nbsp;</div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">Mobile</label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="mobile"
                                      onChange={(e) => setForm(e)}
                                    />
                                  </div>
                                </div>
                                <div>&nbsp;</div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">Address</label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="address"
                                      onChange={(e) => setForm(e)}
                                    />
                                  </div>
                                </div>
                                <div>&nbsp;</div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">Type</label>
                                  <div className="col-lg-9">
                                    <select
                                      className="form-control"
                                      name="addressType"
                                      onChange={(e) => setForm(e)}
                                    >
                                      <option value="" disabled selected>
                                        -Select Address Type-
                                      </option>
                                      <option>Home</option>
                                      <option>Office</option>
                                    </select>
                                  </div>
                                </div>
                                <div>&nbsp;</div>
                              </div>
                              <div className="col-xl-6">
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">Landmark !</label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="landmark"
                                      onChange={(e) => setForm(e)}
                                    />
                                  </div>
                                </div>
                                <div>&nbsp;</div>

                                <div className="form-group row">
                                  <label className="col-form-label col-lg-3">State</label>
                                  <div className="col-lg-9">
                                    <select
                                      className="form-control"
                                      name="state"
                                      onChange={(e) => setForm(e)}
                                    >
                                      <option value="" disabled selected>
                                        -Select State-
                                      </option>
                                      {getStateList()}
                                    </select>
                                  </div>
                                </div>
                                <div>&nbsp;</div>
                                <div className="form-group row">
                                  <label className="col-form-label col-lg-3">City</label>
                                  <div className="col-lg-9">
                                    <select
                                      className="form-control"
                                      name="city"
                                      onChange={(e) => setForm(e)}
                                    >
                                      <option value="" disabled selected>
                                        -Select City-
                                      </option>
                                      {getCityList()}
                                    </select>
                                  </div>
                                </div>
                                <div>&nbsp;</div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">Pin Code</label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="pincode"
                                      onChange={(e) => setForm(e)}
                                    />
                                  </div>
                                </div>
                                <div>&nbsp;</div>
                                <div className="form-group row">
                                  <label className="col-form-label col-lg-3">Country</label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="country"
                                      value="India"
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div>&nbsp;</div>
                              </div>
                            </div>
                          </form>
                          <div className="text-center m-2">
                            <button
                              onClick={() => onCloseModal()}
                              type="button"
                              className="btn btn-primary  m-2 uploadbtn"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={() => SubmitNewAddress()}
                              type="button"
                              className="btn btn-primary m-2 uploadbtn"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>{" "}
      <Modal
        style={{ maxWidth: "1000px", width: "100%" }}
        isOpen={editUserAddress}
        toggle={onCloseModal}
        classNames="popupModalRegisterUser"
      >
        <div style={{ width: "100%" }}>
          <div>
            <div className="page-wrapper  ">
              <div className="card-header">
                <h1 className="card-title mb-0" style={{ textAlign: "center" }}>
                  Edit Billing Address
                </h1>
                <div className="content container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body" style={{ backgroundColor: "white" }}>
                          <h4 className="card-title"></h4>
                          <form action="#">
                            <div className="row">
                              <div className="col-xl-6">
                                <div className="form-group row ">
                                  <label className="col-lg-3 col-form-label">Name</label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="name"
                                      value={updateAddressEdit.name}
                                      onChange={(e) => setForm2(e)}
                                    />
                                  </div>
                                </div>
                                <div>&nbsp;</div>

                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">Email</label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="email"
                                      value={updateAddressEdit.email}
                                      onChange={(e) => setForm2(e)}
                                    />
                                  </div>
                                </div>
                                <div>&nbsp;</div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">Mobile</label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="mobile"
                                      value={updateAddressEdit.mobile}
                                      onChange={(e) => setForm2(e)}
                                    />
                                  </div>
                                </div>
                                <div>&nbsp;</div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">Address</label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="address"
                                      value={updateAddressEdit.address}
                                      onChange={(e) => setForm2(e)}
                                    />
                                  </div>
                                </div>
                                <div>&nbsp;</div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">Type</label>
                                  <div className="col-lg-9">
                                    <select
                                      className="form-control"
                                      name="addressType"
                                      value={updateAddressEdit.addressType}
                                      onChange={(e) => setForm2(e)}
                                    >
                                      <option>Home</option>
                                      <option>Office</option>
                                    </select>
                                  </div>
                                </div>
                                <div>&nbsp;</div>
                              </div>
                              <div className="col-xl-6">
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">Landmark !</label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="landmark"
                                      value={updateAddressEdit.landmark}
                                      onChange={(e) => setForm2(e)}
                                    />
                                  </div>
                                </div>
                                <div>&nbsp;</div>

                                <div className="form-group row">
                                  <label className="col-form-label col-lg-3">State</label>
                                  <div className="col-lg-9">
                                    <select
                                      className="form-control"
                                      name="state"
                                      value={updateAddressEdit.state}
                                      onChange={(e) => setForm2(e)}
                                    >
                                      <option value="" disabled selected>
                                        -Select State-
                                      </option>
                                      {getStateList()}
                                    </select>
                                  </div>
                                </div>
                                <div>&nbsp;</div>
                                <div className="form-group row">
                                  <label className="col-form-label col-lg-3">City</label>
                                  <div className="col-lg-9">
                                    <select
                                      className="form-control"
                                      name="city"
                                      value={updateAddressEdit.city}
                                      onChange={(e) => setForm2(e)}
                                    >
                                      <option value="" disabled selected>
                                        -Select City-
                                      </option>
                                      {getCityList2()}
                                    </select>
                                  </div>
                                </div>
                                <div>&nbsp;</div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">Pin Code</label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="pincode"
                                      value={updateAddressEdit.pincode}
                                      onChange={(e) => setForm2(e)}
                                    />
                                  </div>
                                </div>
                                <div>&nbsp;</div>
                                <div className="form-group row">
                                  <label className="col-form-label col-lg-3">Country</label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="country"
                                      value="India"
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div>&nbsp;</div>
                              </div>
                            </div>
                          </form>
                          <div className="text-center m-2">
                            <button
                              onClick={() => onCloseModal()}
                              type="button"
                              className="btn btn-primary  m-2 uploadbtn"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={() => SubmitEditAddress()}
                              type="button"
                              className="btn btn-primary m-2 uploadbtn"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal isOpen={addExtraImagesOpen} toggle={onCloseModal}>
        <div className="row">
          <div className="col-md-5">
            <div className="card-body">
              <h6 className="text-center">select image</h6>
              <br />

              <img alt="" src={dotted} className="imgbody" />
            </div>
          </div>

          <div className="col-md-5">
            <div className="card-body">
              <br />
              <input placeholder=" enter title ..." onChange={(e) => setTital(e.target.value)} />
              <br />
              <br />
              <input type="file" onChange={(e) => setImage3(e.target.files[0])} />
              <br />
              <br />
              <button className="w-100 btn uploadbtn" onClick={() => uploadImage3()}>
                <i className="fa fa-upload m-2" aria-hidden="true" />
                <i aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.orders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeorders: (orders) => {
      dispatch(changeOrders(orders));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CartList);
