import React, { useEffect, useState } from "react";
import axios from "axios";
import { url } from "./helper";

const Profile = (props) => {
  const userId = localStorage.getItem("businessid");
  const [userProfile, setUserProfile] = useState("");
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    axios.get(`${url}/api/business/user/${userId}`).then((res) => setUserProfile(res.data.data));
  };

  return (
    <>
      <div
        className="page-wrapper  "
        style={{ width: "100%", paddingLeft: "10%", paddingRight: "10%" }}
      >
        <div className="card-header" style={{ backgroundColor: "white" }}>
          <h1 className="card-title mb-0">My Profile </h1>
          {userProfile ? (
            <i
              className="fa fa-edit m-2"
              style={{ color: "rgb(84,84,156)" }}
              onClick={() => props.history.push("EditProfile")}
            ></i>
          ) : (
            <i
              className="fa fa-plus m-2"
              style={{ color: "rgb(84,84,156)" }}
              onClick={() => props.history.push("Signup")}
            ></i>
          )}
          <div className="content container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body" style={{ backgroundColor: "white" }}>
                    <h4 className="card-title"></h4>
                    <form action="#">
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group row ">
                            <label className="col-lg-3 col-form-label">First Name</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="firstName"
                                value={userProfile.firstName}
                                disabled
                              />
                            </div>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">Website</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="website"
                                value={userProfile.website}
                                disabled
                              />
                            </div>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">Email</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="email"
                                value={userProfile.email}
                                disabled
                              />
                            </div>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">Mobile</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="mobile"
                                value={userProfile.mobile}
                                disabled
                              />
                            </div>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">PAN Number</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="pannumber"
                                value={userProfile.pannumber}
                                disabled
                              />
                            </div>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label ">GST Number</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="gstnumber"
                                value={userProfile.gstnumber}
                                disabled
                              />
                            </div>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">last Name</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="lastName"
                                value={userProfile.lastName}
                                disabled
                              />
                            </div>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">Organization</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="organization"
                                value={userProfile.organization}
                                disabled
                              />
                            </div>
                          </div>
                          <div>&nbsp;</div>

                          <div className="form-group row">
                            <label className="col-form-label col-lg-3">Country</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="country"
                                value={userProfile.country}
                                disabled
                              />
                            </div>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label className="col-form-label col-lg-3">State</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="state"
                                value={userProfile.state}
                                disabled
                              />
                            </div>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label className="col-form-label col-lg-3">City</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="city"
                                value={userProfile.city}
                                disabled
                              />
                            </div>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">Pin Code</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="pincode"
                                value={userProfile.pincode}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
