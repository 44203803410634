import React from "react";

function Invoice() {
  return (
    <div style={{ width: "90%", paddingLeft: "12%" }}>
      <div style={{ border: "3px solid black" }}>
        <div className="container pt-3">
          <div className="row">
            <div className="col">
              <a className href="#">
                <img height={40} src="img/print.jpg" alt="printbazar" />
              </a>
            </div>
            <div className="col">
              <p>
                Plot No. 210, Next to Hotel Amer Palace,Zone-1,M.P. Nagar, Bhopal|Mob:7880003671-73
                <br />
                Email : info@printbazar.com, Web : www.printbazar.com
                <br />
                GSTIN :{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">Invoice No.21-22/99</div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="card-body">Invoice No.21-22/99</div>
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-3">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  Bill To,
                  <div style={{ paddingTop: "20%" }}>
                    GSTIN:
                    <br />
                    Mob:
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="card-body">
                  Supply To,
                  <div style={{ paddingTop: "20%" }}>
                    GSTIN:
                    <br />
                    Mob:
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Decription</th>
                <th scope="col">Quality</th>
                <th scope="col">Rate</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  Leaflets
                  <br />
                  A-4 size on 90 gsm Both Side
                </th>
                <td>10000</td>
                <td>10000</td>
                <td>10000</td>
              </tr>
              <tr>
                <th scope="row">
                  Leaflets
                  <br />
                  A-4 size on 90 gsm Both Side
                </th>
                <td>10000</td>
                <td>10000</td>
                <td>10000</td>
              </tr>
              <tr>
                <th scope="row">
                  Leaflets
                  <br />
                  A-4 size on 90 gsm Both Side
                </th>
                <td>10000</td>
                <td>10000</td>
                <td>10000</td>
              </tr>
              <tr>
                <th scope="row">
                  Leaflets
                  <br />
                  A-4 size on 90 gsm Both Side
                </th>
                <td>10000</td>
                <td>10000</td>
                <td>10000</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <div className="row">
            <div className="col-sm-8">
              <div className="col">
                <div className="card">
                  <div className="card-body">
                    Payment Type
                    <div style={{ paddingTop: "70px" }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="row">Total</th>
                    <td>40000/-</td>
                  </tr>
                  <tr>
                    <th scope="row">GST 12%</th>
                    <td>4800/-</td>
                  </tr>
                  <tr>
                    <th scope="row">G.Total</th>
                    <td>44800</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <div className="container">
          <div style={{ textAlign: "right", padding: "50px" }}>Authorised Signatory</div>
        </div>
      </div>
    </div>
  );
}

export default Invoice;
