import React, { useState } from "react";
import axios from "axios";
import { url } from "./helper";
import yourhandle from "countrycitystatejson";
const allCountries = yourhandle.getCountries();

const Signup = (props) => {
  const userId = localStorage.getItem("businessid");
  const [updateUser, setUpdateUser] = useState({
    userId: userId,
    firstName: "",
    lastName: "",
    organization: "",
    country: "India",
    state: "",
    city: "",
    pincode: "",
    mobile: "",
    email: "",
    website: "",
    gstnumber: "",
    pannumber: "",
  });
  const setForm = (event) => {
    let { name, value } = event.target;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    setUpdateUser({
      ...updateUser,
      [name]: value,
    });
  };
  const SubmitUserHandler = async () => {
    await axios.post(`${url}/api/business`, updateUser).then((response) => {
      alert("new user created");
      props.history.push("Login");
    });
  };
  const getStateList = () => {
    const list = yourhandle.getStatesByShort("IN");
    return list.map((i) => <option>{i}</option>);
  };
  const getCityList = () => {
    const list = yourhandle.getCities("IN", updateUser.state);
    return list.map((i) => <option>{i}</option>);
  };
  return (
    <>
      <div
        className="page-wrapper  "
        style={{ width: "100%", paddingLeft: "10%", paddingRight: "10%" }}
      >
        <div className="card-header" style={{ backgroundColor: "white" }}>
          <h1 className="card-title mb-0">Update Profile</h1>
          <div className="content container-fluid">
            {/* /Page Header */}

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body" style={{ backgroundColor: "white" }}>
                    <h4 className="card-title"></h4>
                    <form action="#">
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group row ">
                            <label className="col-lg-3 col-form-label">First Name</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="firstName"
                                onChange={(e) => setForm(e)}
                              />
                            </div>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">Website</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="website"
                                onChange={(e) => setForm(e)}
                              />
                            </div>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">Email</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="email"
                                onChange={(e) => setForm(e)}
                              />
                            </div>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">Mobile</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="mobile"
                                onChange={(e) => setForm(e)}
                              />
                            </div>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">PAN Number</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="pannumber"
                                onChange={(e) => setForm(e)}
                              />
                            </div>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label ">GST Number</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="gstnumber"
                                onChange={(e) => setForm(e)}
                              />
                            </div>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">last Name</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="lastName"
                                onChange={(e) => setForm(e)}
                              />
                            </div>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">Organization</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="organization"
                                onChange={(e) => setForm(e)}
                              />
                            </div>
                          </div>
                          <div>&nbsp;</div>

                          <div className="form-group row">
                            <label className="col-form-label col-lg-3">Country</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="country"
                                value={updateUser.country}
                                disabled
                              />
                            </div>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label className="col-form-label col-lg-3">State</label>
                            <div className="col-lg-9">
                              <select
                                className="form-control"
                                name="state"
                                onChange={(e) => setForm(e)}
                              >
                                <option value="" disabled selected>
                                  -Select State-
                                </option>
                                {getStateList()}
                              </select>
                            </div>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label className="col-form-label col-lg-3">City</label>
                            <div className="col-lg-9">
                              <select
                                className="form-control"
                                name="city"
                                value={updateUser.city}
                                onChange={(e) => setForm(e)}
                              >
                                <option value="" disabled selected>
                                  -Select City-
                                </option>
                                {getCityList()}
                              </select>
                            </div>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">Pin Code</label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="pincode"
                                onChange={(e) => setForm(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="text-center mt-3">
                      <button
                        onClick={() => SubmitUserHandler()}
                        type="button"
                        className="btn btn-primary btn-lg"
                        style={{
                          paddingLeft: "8%",
                          paddingRight: "8%",
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
