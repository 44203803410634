import React, { useState, useEffect } from "react";
import axios from "axios";
import { url } from "./helper";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import img1 from "./Images/sheetwise/Poster01.jpg";
import img2 from "./Images/sheetwise/Poster02.jpg";
import img3 from "./Images/sheetwise/Poster03.jpg";
import img4 from "./Images/sheetwise/Poster04.jpg";
import Cart from "./Cart";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { connect } from "react-redux";
import { changeOrders } from "../actions/action";
import Login from "./Login1";

const Sheetwise = (props) => {
  const goToHome = () => {
    props.history.push("");
  };
  const loginoff = () => {
    setShowLogin(false);
    setEditUser(false);
  };
  const token = localStorage.getItem("token");
  const isLoggedIn = token ? true : false;
  const [editUser, setEditUser] = useState(false);
  const businessId = localStorage.getItem("businessid");
  const [updateOrder, setUpdateOrder] = useState({
    businessId: businessId,
    orderFor: "SheetWise",
    design1: "",
    design2: "",
    cdr: "",
    size: "",
    paperType: "",
    gsm: "",
    printSide: "",
    Quantity: "",
    numberOfcolors: "",
    LaminationFabricFinishing: false,
    LaminationType: "",
    LaminationSide: "",
  });

  const [gsmData, setGsmData] = useState([]);
  useEffect(() => {
    getGsmData();
  }, []);
  const url9 = `${url}/api/gsm`;
  const getGsmData = async () => {
    try {
      const response = await axios.get(url9);
      setGsmData(response.data.data);
    } catch (error) {
      var err2 = error.response;
      console.log(err2);
    }
  };
  const [sizeData, setSizeData] = useState([]);
  useEffect(() => {
    getSizeData();
  }, []);
  const url8 = `${url}/api/sizes`;
  const getSizeData = async () => {
    try {
      const response = await axios.get(url8);
      setSizeData(response.data.data);
    } catch (error) {
      var err2 = error.response;
      console.log(err2);
    }
  };
  const [paperPrice, setPaperPrice] = useState([]);
  const [colorPrice, setColorPrice] = useState([]);
  const [laminationPrice, setLaminationPrice] = useState([]);
  const laminationsideValue = [
    { name: "Single Side", q: 1 },
    { name: "Double Side", q: 2 },
  ];
  const printsideValue = [
    { name: "Single Side", q: 1 },
    { name: "Double Side", q: 2 },
  ];
  const gsms = gsmData.filter((item) => item.paperName === updateOrder.paperType).map((i) => i.gsm);
  const sizes = sizeData.filter((item) => item.paperName === updateOrder.paperType);
  const PaperPrice = () => {
    const selectedPaperType = paperPrice.filter((i) => i.name === updateOrder.paperType)[0];
    const selectedSize = sizes.filter((item) => item.name === updateOrder.size)[0];
    const paperprice =
      ((selectedSize.b * selectedSize.l * updateOrder.gsm) / 1550000) *
      selectedPaperType.price *
      (updateOrder.Quantity / selectedSize.q);
    const totalprice = Math.round(paperprice);
    return totalprice;
  };
  const PrintingPrice = () => {
    const selectedPrintside = printsideValue.filter((i) => i.name === updateOrder.printSide)[0];
    const selectedColor = colorPrice.filter((i) => i.name === updateOrder.numberOfcolors)[0];
    const selectedSize = sizes.filter((item) => item.name === updateOrder.size)[0];
    const printingprice =
      Math.ceil((updateOrder.Quantity / 1000 / (selectedSize.q / 2)) * selectedPrintside.q) *
        selectedColor.price +
      selectedColor.plate;
    const totalprice = Math.round(printingprice);
    return totalprice;
  };
  const LaminationPrice = () => {
    const selectedSize = sizes.filter((item) => item.name === updateOrder.size)[0];
    const selectedLaminationSide = laminationsideValue.filter(
      (i) => i.name === updateOrder.LaminationSide,
    )[0];
    const selectedLamination = laminationPrice.filter(
      (i) => i.name === updateOrder.LaminationType,
    )[0];
    const sheetquantity = updateOrder.Quantity / selectedSize.q;
    const laminationcost =
      selectedSize.b *
      selectedSize.l *
      selectedLamination.price *
      selectedLaminationSide.q *
      sheetquantity;
    const totalpricewithlamination = Math.round(laminationcost);
    return totalpricewithlamination;
  };

  const TotalPrice = () => {
    const totalPrice =
      PaperPrice() +
      PrintingPrice() +
      ((updateOrder.LaminationFabricFinishing === true) &
      (updateOrder.LaminationType !== "") &
      (updateOrder.LaminationSide !== "")
        ? LaminationPrice()
        : 0);
    return totalPrice;
  };

  const [showDetails, setShowDetails] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const setForm = (event) => {
    let { name, value } = event.target;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    setUpdateOrder({
      ...updateOrder,
      [name]: value,
    });
  };
  const setFormpt = (event) => {
    let { name, value } = event.target;
    const paperName = value.split("(")[0];
    setUpdateOrder({
      ...updateOrder,
      [name]: paperName,
    });
  };
  const SubmitOrderHandler = async () => {
    const paperPrice = PaperPrice();
    const printingPrice = PrintingPrice();
    const laminationPrice = updateOrder.LaminationFabricFinishing === true ? LaminationPrice() : 0;
    const totalPrice = TotalPrice();
    const newOrder = {
      ...updateOrder,
      paperPrice: paperPrice,
      printingPrice: printingPrice,
      laminationPrice: laminationPrice,
      totalPrice: totalPrice,
    };
    if (isLoggedIn) {
      await axios
        .post(`${url}/api/order`, newOrder)
        .then((res) => props.changeorders([...props.orders, res.data.data]))
        .then(() => {
          alert("New Order Added To Cart");
          setShowCart(false);
        });
    } else {
      localStorage.setItem("currentpage", "Sheetwise");
      setEditUser(true);
    }
  };
  const showDetailsHandler = () => {
    if (updateOrder.paperType === "") {
      alert("select paper type");
    } else if (updateOrder.gsm === "") {
      alert("select gsm");
    } else if (updateOrder.printSide === "") {
      alert("select print side");
    } else if (updateOrder.Quantity === "") {
      alert("enter Quantity");
    } else if (updateOrder.size === "") {
      alert("select size");
    } else if (updateOrder.numberOfcolors === "") {
      alert("select number of colors");
    } else if (updateOrder.Quantity === "") {
      alert("enter Quantity");
    } else {
      setShowDetails(true);
    }
  };
  useEffect(() => {
    getApiMathod1();
  }, []);
  const url2 = `${url}/api/paperprice`;
  const getApiMathod1 = async () => {
    try {
      const response = await axios.get(url2);

      setPaperPrice(response.data.data.filter((item) => item.forSheetwisePrinting));
    } catch (error) {
      var err2 = error.response;
      console.log(err2);
    }
  };
  useEffect(() => {
    getApiMathod2();
  }, []);
  const url3 = `${url}/api/colorprice`;
  const getApiMathod2 = async () => {
    try {
      const response = await axios.get(url3);

      setColorPrice(response.data.data);
    } catch (error) {
      var err2 = error.response;
      console.log(err2);
    }
  };
  useEffect(() => {
    getApiMathod3();
  }, []);
  const url4 = `${url}/api/laminationprice`;
  const getApiMathod3 = async () => {
    try {
      const response = await axios.get(url4);

      setLaminationPrice(response.data.data);
    } catch (error) {
      var err2 = error.response;
      console.log(err2);
    }
  };
  const onCloseModal = () => {
    setEditUser(false);
  };
  const PaperBrand = () => {
    if (paperPrice.filter((i) => i.name === updateOrder.paperType).length) {
      const selectedPaperType = paperPrice.filter((i) => i.name === updateOrder.paperType)[0];
      return `(${selectedPaperType.companyName})`;
    }
  };
  const PaperSays = () => {
    if (sizes.filter((item) => item.name === updateOrder.size).length) {
      const selectedSize = sizes.filter((item) => item.name === updateOrder.size)[0];
      return `(${selectedSize.says})`;
    }
  };
  return (
    <>
      {showLogin ? (
        <Login loginoff={loginoff} />
      ) : (
        <>
          <div className="bg-white">
            <div className="container">
              <h2>SHEETWISE PRINTING</h2>
              <div className="row mb-3">
                <div className="col-sm-6">
                  <div className="row">
                    <div
                      id="carouselExampleIndicators"
                      className="carousel slide w-100"
                      data-ride="carousel"
                    >
                      <div className="carousel-inner" style={{ paddingTop: "1rem" }}>
                        <CarouselProvider
                          naturalSlideWidth={100}
                          naturalSlideHeight={70}
                          totalSlides={4}
                        >
                          <Slider>
                            <Slide index={0}>
                              {" "}
                              <img src={img1} className="w-100" />
                            </Slide>
                            <Slide index={1}>
                              {" "}
                              <img src={img2} className="w-100" />
                            </Slide>
                            <Slide index={2}>
                              {" "}
                              <img src={img3} className="w-100" />
                            </Slide>
                            <Slide index={3}>
                              {" "}
                              <img src={img4} className="w-100" />
                            </Slide>
                          </Slider>
                          <ButtonNext className="carousel-control-next">
                            <i class="fa fa-arrow-right fa-2x" aria-hidden="true"></i>
                          </ButtonNext>
                          <ButtonBack className="carousel-control-prev">
                            <i class="fa fa-arrow-left fa-2x" aria-hidden="true"></i>
                          </ButtonBack>
                        </CarouselProvider>
                      </div>
                      <div className="row justify-content-center mt-2">
                        <div className="col-2  ">
                          <div
                            className="row"
                            data-target="#carouselExampleIndicators"
                            data-slide-to={0}
                          >
                            <img src={img1} className="img-fluid" />
                          </div>
                        </div>
                        <div className="col-2  ">
                          <div
                            className="row"
                            data-target="#carouselExampleIndicators"
                            data-slide-to={1}
                          >
                            <img src={img2} className="img-fluid" />
                          </div>
                        </div>
                        <div className="col-2 ">
                          <div
                            className="row"
                            data-target="#carouselExampleIndicators"
                            data-slide-to={2}
                          >
                            <img src={img3} className="img-fluid" />
                          </div>
                        </div>
                        <div className="col-2 ">
                          <div
                            className="row"
                            data-target="#carouselExampleIndicators"
                            data-slide-to={2}
                          >
                            <img src={img4} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="m-3 p-4 bg-white justify-content-center">
                    <div
                      className="p-0 m-0 text-center bg-light text-dark my-auto"
                      style={{ borderRadius: "10px" }}
                    >
                      <h4 className=" m-0 p-2">Sheet Wise Calculation</h4>
                    </div>
                    <div>&nbsp;</div>
                    <form action="#">
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group row">
                            <label style={{ textAlign: "left" }}>Paper Type</label>

                            <select
                              className="form-control"
                              name="paperType"
                              onChange={(e) => setFormpt(e)}
                            >
                              <option value="" disabled selected>
                                -Select Paper Type-
                              </option>
                              {paperPrice.map((item) => (
                                <option>
                                  {item.name}({item.companyName})
                                </option>
                              ))}
                            </select>
                          </div>
                          <div style={{ color: "rgb(30, 115, 253)" }}>&nbsp;</div>
                          <div className="form-group row">
                            <label style={{ textAlign: "left" }}>Paper Size*</label>

                            <select
                              className="form-control"
                              name="size"
                              onChange={(e) => setForm(e)}
                            >
                              <option value="" disabled selected>
                                -Select Paper Size-
                              </option>
                              {sizes.map((item) => (
                                <option>{item.name}</option>
                              ))}
                            </select>
                          </div>
                          <div style={{ color: "rgb(30, 115, 253)" }}>
                            &nbsp;
                            {PaperSays()}
                          </div>
                          <div className="form-group row">
                            <label style={{ textAlign: "left" }}>No of Colors *</label>
                            <select
                              className="form-control"
                              name="numberOfcolors"
                              onChange={(e) => setForm(e)}
                            >
                              <option value="" disabled selected>
                                -Select No. Of Color-
                              </option>
                              {colorPrice.map((item) => (
                                <option>{item.name}</option>
                              ))}
                            </select>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group row">
                            <label style={{ textAlign: "left" }}>GSM *</label>

                            <select
                              className="form-control"
                              name="gsm"
                              onChange={(e) => setForm(e)}
                            >
                              <option value="" disabled selected>
                                -Select GSM-
                              </option>
                              {gsms.map((item) => (
                                <option>{item}</option>
                              ))}
                            </select>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label style={{ textAlign: "left" }}>Print Side *</label>
                            <select
                              className="form-control"
                              name="printSide"
                              onChange={(e) => setForm(e)}
                            >
                              <option value="" disabled selected>
                                -Select Print Side-
                              </option>
                              <option>Single Side</option>
                              <option>Double Side</option>
                            </select>
                          </div>
                          <div>&nbsp;</div>
                          <div className="form-group row">
                            <label style={{ textAlign: "left" }}>Quantity</label>
                            <input
                              Placeholder="-Enter Quantity-"
                              type="text"
                              className="form-control text-bold"
                              name="Quantity"
                              onChange={(e) => setForm(e)}
                            />
                          </div>
                          <div>&nbsp;</div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="exampleCheck1"
                            name="LaminationFabricFinishing"
                            onChange={(e) => setForm(e)}
                          />
                          <label style={{ textAlign: "left", marginLeft: "5%" }}>
                            Lamination, Fabric & Finishing
                          </label>
                        </div>
                        {updateOrder.LaminationFabricFinishing === true ? (
                          <form action="#">
                            <div className="row">
                              <div className="col-xl-6">
                                <div className="form-group row">
                                  <label style={{ textAlign: "left" }}>Lamination</label>

                                  <select
                                    className="form-control"
                                    name="LaminationType"
                                    onChange={(e) => setForm(e)}
                                  >
                                    <option value="" disabled selected>
                                      -Select Lamination-
                                    </option>
                                    {laminationPrice.map((item) => (
                                      <option>{item.name}</option>
                                    ))}
                                  </select>
                                </div>
                                <div>&nbsp;</div>
                              </div>
                              <div className="col-xl-6">
                                <div className="form-group row">
                                  <label style={{ textAlign: "left" }}>Lamination Side*</label>

                                  <select
                                    className="form-control"
                                    name="LaminationSide"
                                    onChange={(e) => setForm(e)}
                                  >
                                    <option value="" disabled selected>
                                      -Select Lamination Side-
                                    </option>
                                    <option>Single Side</option>
                                    <option>Double Side</option>
                                  </select>
                                </div>
                                <div>&nbsp;</div>
                              </div>
                            </div>
                          </form>
                        ) : null}

                        <div className="text-center mt-3">
                          <button
                            type="button"
                            className="btn btn-primary btn-lg"
                            style={{
                              paddingLeft: "5.5rem",
                              paddingRight: "5.5rem",
                            }}
                            onClick={() => showDetailsHandler()}
                          >
                            Calculate Price
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showDetails === true ? (
            <div className="container" style={{ paddingBottom: "2%" }}>
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{
                        backgroundColor: "rgb(6,120,139)",
                        color: "white",
                      }}
                    >
                      <p className="lead fw-bold">Sheetwise Printing</p>
                      <div className="row">
                        <div className="col-sm-8">Specification</div>
                        <div className="col-sm-4" align="center">
                          Quantity
                        </div>
                      </div>
                    </div>
                    <div className="card-body" style={{ backgroundColor: "azure" }} mat-typography>
                      <div className="row">
                        <div className="col-sm-8">
                          {/* <h4 className="card-title"> Brochure</h4> */}
                          <p className="card-text">
                            <b>
                              {" "}
                              Size : {updateOrder.size} on {updateOrder.gsm} gsm
                            </b>{" "}
                            <br></br> <b>paper : {updateOrder.paperType} paper</b> <br></br>
                            <b> Color : {updateOrder.numberOfcolors} </b>{" "}
                          </p>
                          {updateOrder.LaminationType !== "" ? (
                            <p>
                              <strong> Finishing : {updateOrder.LaminationType}</strong>
                            </p>
                          ) : null}
                        </div>
                        <div className="col-sm-4" align="center">
                          <h5> {updateOrder.Quantity}</h5>
                        </div>
                      </div>
                    </div>
                    <div
                      className="card-footer"
                      style={{
                        backgroundColor: "rgb(6,120,139)",
                        color: "white",
                      }}
                    >
                      {/* <h6 align="end">Paper Price : {PaperPrice()}</h6>
                  <h6 align="end">Printing Price :{PrintingPrice()} </h6>
                  {(updateOrder.LaminationFabricFinishing === true) &
                    (updateOrder.LaminationType !== '') &
                    (updateOrder.LaminationSide !== '') ? (
                    <h6 align="end">Lamination Price : {LaminationPrice()} </h6>
                  ) : null} */}
                      <h6 align="end">Total Price :{TotalPrice()}</h6>
                    </div>
                  </div>
                  <div className="text-center mt-3">
                    <button
                      type="button"
                      className="btn btn-primary btn-lg"
                      style={{ paddingLeft: "5.5rem", paddingRight: "5.5rem" }}
                      onClick={() => SubmitOrderHandler()}
                    >
                      Add To Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {showCart ? <Cart goToHome={goToHome} /> : null}
          {/* {JSON.stringify(finalList)} */}
          <Modal isOpen={editUser} toggle={onCloseModal} classNames="">
            <ModalBody>
              <div class="container">
                <div style={{ textAlign: "center" }}>If you are already a member !</div>
                <div
                  class="row"
                  style={{
                    marginLeft: "20%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <button
                    onClick={() => setShowLogin(true)}
                    style={{
                      width: "27%",
                      border: "none",
                      background: "#0d6efd",
                      borderRadius: 5,
                      color: "white",
                    }}
                  >
                    Login
                  </button>

                  <button
                    onClick={() => setShowLogin(true)}
                    style={{
                      width: "27%",
                      marginLeft: "20%",
                      border: "none",
                      background: "#0d6efd",
                      borderRadius: 5,
                      color: "white",
                    }}
                  >
                    SignUp
                  </button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.orders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeorders: (orders) => {
      dispatch(changeOrders(orders));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sheetwise);
