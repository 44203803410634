import React, { useState, useEffect } from "react";
import axios from "axios";
import cdr from "./cdr.png";
import { url } from "./helper";

const Cart = (props) => {
  const [customerData, setCustomerData] = useState({});
  const orderId = props.orderId;
  const url2 = `${url}/api/order`;
  useEffect(async () => {
    getApiMathod();
  }, []);

  const getApiMathod = async () => {
    await axios.get(`${url2}/${orderId}`).then((response) => {
      setCustomerData(response.data.data);
    });
  };

  const [imageData, setImageData] = useState([]);
  useEffect(async () => {
    getImageData();
  }, []);

  const getImageData = async () => {
    await axios.get(`${url}/api/image`).then((response) => {
      setImageData(response.data.data);
    });
  };
  const [gstData, setGstData] = useState([]);
  const gst = (x) => {
    if (gstData.filter((i) => i.product === x).length > 0) {
      const gst = gstData.filter((i) => i.product === x)[0].gst;
      return gst;
    } else {
      return 0;
    }
  };
  useEffect(async () => {
    getGst();
  }, []);

  const getGst = async () => {
    await axios.get(`${url}/api/gst`).then((response) => {
      setGstData(response.data.data);
    });
  };
  return (
    <>
      <div className="container">
        <i
          onClick={() => props.closeCart()}
          className="fa fa-times fa-2x "
          style={{ float: "right", color: "rgb(84,84,156)" }}
        ></i>
        <div className="row mb-4">
          <div className="col-sm-8" style={{ paddingLeft: "6%" }}>
            <div className="card shadow-sm ">
              <div className="row ">
                <div className="col-md-4 col-sm-6 ">
                  <div className="card-body">
                    <h6 className="text-center">Front Side</h6>
                    <img
                      src={
                        customerData.design1 !== ""
                          ? customerData.design1
                          : "https://i2.wp.com/graphicyard.com/wp-content/uploads/2019/08/Print-Bi-Fold-Brochure-Design-3.jpg?fit=2500%2C1667&ssl=1"
                      }
                      className="imgbody"
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-6">
                  <div className="card-body">
                    <h6 className="text-center">Back Side</h6>
                    <img
                      src={
                        customerData.design2 !== ""
                          ? customerData.design2
                          : "https://i2.wp.com/graphicyard.com/wp-content/uploads/2019/08/Print-Bi-Fold-Brochure-Design-3.jpg?fit=2500%2C1667&ssl=1"
                      }
                      className="imgbody"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="card-body">
                    <h6 className="text-center">Coral Design</h6>
                    <img
                      src={
                        customerData.cdr !== ""
                          ? cdr
                          : "https://i2.wp.com/graphicyard.com/wp-content/uploads/2019/08/Print-Bi-Fold-Brochure-Design-3.jpg?fit=2500%2C1667&ssl=1"
                      }
                      className="imgbody"
                    />
                  </div>
                </div>

                {imageData
                  .filter((i) => i.orderId === customerData._id)
                  .map((j) => (
                    <>
                      <div className="col-md-4 col-sm-6 ">
                        <div className="card-body">
                          <h6 className="text-center">{j.tital}</h6>
                          <img
                            src={
                              j.image !== ""
                                ? j.image
                                : "https://i2.wp.com/graphicyard.com/wp-content/uploads/2019/08/Print-Bi-Fold-Brochure-Design-3.jpg?fit=2500%2C1667&ssl=1"
                            }
                            className="imgbody"
                          />
                        </div>
                      </div>
                    </>
                  ))}
              </div>

              <div className="card-body ">
                <hr className="mt-0 pt-0" />
                <div className="row">
                  <div className="col-sm-6  col-6">
                    <h5>Quantiy : {customerData.Quantity}</h5>
                  </div>
                  <div className="col-sm-6  col-6" align="end">
                    <h5>Price :{customerData.totalPrice}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 " style={{ paddingRight: "4%" }}>
            <div className="card shadow-sm">
              <div className="card-header pb-0 mb-0">
                <div className="row">
                  <div className="col-sm-6  col-6">
                    <h6>Item</h6>
                  </div>
                  <div className="col-sm-6  col-6" align="end">
                    <h6>Amount</h6>
                  </div>
                </div>
              </div>
              <div className="card-body" style={{ paddingTop: "7%" }}>
                {/* <div className="row">
                  <h4>{customerData.orderFor}</h4>
                  <br />
                  <p className="card-text">
                    Size : {customerData.size} | GSM : {customerData.gsm} gsm | Paper :{' '}
                    {customerData.paperType} Paper
                    <br /> Color : {customerData.numberOfcolors}{' '}
                    {customerData.paperSide ? `| Side : ${customerData.paperSide}` : ''}
                  </p>
                  <p></p>
                  {customerData.LaminationFabricFinishing ? (
                    <p className="card-text">
                      Finishing : {customerData.LaminationType}{' '}
                      {customerData.LaminationSide ? `| Side : ${customerData.LaminationSide}` : ''}
                    </p>
                  ) : null}
                  {customerData.tapping ? (
                    <p className="card-text">Gumming : {customerData.gumming}</p>
                  ) : null}
                </div>{' '} */}
                {/* <div className="row">
                  <div className="col-sm-6  col-6">
                    <h5>Paper Price</h5>
                    <h5>Printing Price</h5>
                    {customerData.LaminationFabricFinishing ? <h5>Lamination Price</h5> : null}
                    {customerData.tapping ? <h5>Gumming Price</h5> : null}
                  </div>
                  <div className="col-sm-6  col-6" align="end">
                    <h5>{customerData.paperPrice}</h5>
                    <h5>{customerData.printingPrice}</h5>

                    {customerData.LaminationFabricFinishing ? (
                      <h5>{customerData.laminationPrice}</h5>
                    ) : null}
                    {customerData.tapping ? <h5>{customerData.tappingPrice}</h5> : null}
                  </div>
                </div> */}
              </div>
              <div className="card-footer">
                {/* <div className="row">
                  <div className="col-sm-6  col-6">
                    <h6>Total Price</h6>
                    GST @ {gst(customerData.orderFor)}%
                  </div>
                  <div className="col-sm-6  col-6" align="end">
                    <h6>{customerData.totalPrice}</h6>
                    {(customerData.totalPrice * gst(customerData.orderFor)) / 100}
                  </div>
                </div> */}
                <hr />
                <div className="row">
                  <div className="col-sm-6 col-6">
                    <h6>Total Amount</h6>
                  </div>
                  <div className="col-sm-6 col-6" align="end">
                    <h6>{(customerData.totalPrice * (100 + gst(customerData.orderFor))) / 100}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
