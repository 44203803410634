import firebase from "firebase";

// const firebaseConfig = {
//   apiKey: "AIzaSyCPBAQgF8bTMvCVWlU_IOpV-7F0TTp2drc",
//   authDomain: "printbajar-3edba.firebaseapp.com",
//   projectId: "printbajar-3edba",
//   storageBucket: "printbajar-3edba.appspot.com",
//   messagingSenderId: "719533183574",
//   appId: "1:719533183574:web:9db1c35fb15d9a9f0a61f7",
//   measurementId: "G-7W0DG5QC9P",
// };

const firebaseConfig = {
  apiKey: "AIzaSyBgu47yBAXJJJqy_8YvANvYfECS6VlNS6s",
  authDomain: "finalprint-920d7.firebaseapp.com",
  projectId: "finalprint-920d7",
  storageBucket: "finalprint-920d7.appspot.com",
  messagingSenderId: "71060542264",
  appId: "1:71060542264:web:26102af3517ad47a9627cd",
  measurementId: "G-DVG7MCB78Y",
};

firebase.initializeApp(firebaseConfig);
var storage = firebase.storage();
export default storage;
