import React from "react";
import img from "./Images/bookprinting/Book01.jpg";
import img1 from "./Images/browsedesign/gra.jpg";

const Browsedesign = () => {
  return (
    <>
      <div className="container" style={{ display: "flex", width: "30%" }}>
        <div className="row">
          <div className="col-sm-6 col-12 my-auto p-5">
            <h1>Easy Way</h1>
            <h1>To Create Your Design</h1>
            <h1>
              with our <strong>TEMPLATE</strong>
            </h1>
            <button
              color="primary"
              style={{
                width: "120px",
                backgroundColor: "rgb(7,105,144)",
                marginRight: "10px",
                outline: "none",
              }}
              className="flat-button mat-button-base mat-primary"
            >
              <span className="button-wrapper">Custom Design</span>
              <div className="button-ripple ripple"></div>
              <div className="button-focus-overlay" />
            </button>
            <button
              color="primary"
              style={{
                width: "120px",
                backgroundColor: "rgb(7,105,144)",
                marginRight: "10px",
                outline: "none",
              }}
              tabIndex={0}
              className="flat-button mat-button-base mat-primary"
            >
              <span className="button-wrapper">Create Design</span>
              <div className="button-ripple mat-ripple" />
              <div className="button-focus-overlay" />
            </button>
          </div>
          <div className="col-sm-6 col-12 p-2 ">
            <img className="img-fluid h-100" src={img1} />
          </div>
        </div>
      </div>
      <div className="container row" style={{ display: "flex", width: "30%" }}>
        <div class="mat-subheader" mat-subheader="">
          Offset Printing
        </div>
        <button className="btn btn-sm btn-primary">vijay</button>
      </div>
      <div className="container row" style={{ width: "65%" }}>
        <div>
          <div className="gallery">
            <a target="_blank" href="img_5terre.jpg">
              <img src={img} alt="Cinque Terre" width={600} height={400} />
            </a>
            <div className="desc">content here</div>
          </div>
          <div className="gallery">
            <a target="_blank" href={img}>
              <img src={img} alt="Forest" width={600} height={400} />
            </a>
            <div className="desc">content here</div>
          </div>
          <div className="gallery">
            <a target="_blank" href={img}>
              <img src={img} alt="Northern Lights" width={600} height={400} />
            </a>
            <div className="desc">content here</div>
          </div>
          <div className="gallery">
            <a target="_blank" href={img}>
              <img src={img} alt="Mountains" width={600} height={400} />
            </a>
            <div className="desc">content here</div>
          </div>
          <div className="gallery">
            <a target="_blank" href={img}>
              <img src={img} alt="Mountains" width={600} height={400} />
            </a>
            <div className="desc">content here</div>
          </div>
          <div className="gallery">
            <a target="_blank" href={img}>
              <img src={img} alt="Mountains" width={600} height={400} />
            </a>
            <div className="desc">content here</div>
          </div>
          <div className="gallery">
            <a target="_blank" href={img}>
              <img src={img} alt="Mountains" width={600} height={400} />
            </a>
            <div className="desc">content here</div>
          </div>
          <div className="gallery">
            <a target="_blank" href={img}>
              <img src={img} alt="Mountains" width={600} height={400} />
            </a>
            <div className="desc">content here</div>
          </div>
          <div className="gallery">
            <a target="_blank" href={img}>
              <img src={img} alt="Mountains" width={600} height={400} />
            </a>
            <div className="desc">content here</div>
          </div>
          <div className="gallery">
            <a target="_blank" href={img}>
              <img src={img} alt="Mountains" width={600} height={400} />
            </a>
            <div className="desc">content here</div>
          </div>
          <div className="gallery">
            <a target="_blank" href={img}>
              <img src={img} alt="Mountains" width={600} height={400} />
            </a>
            <div className="desc">content here</div>
          </div>
          <div className="gallery">
            <a target="_blank" href={img}>
              <img src={img} alt="Mountains" width={600} height={400} />
            </a>
            <div className="desc">content here</div>
          </div>
          <div className="gallery">
            <a target="_blank" href={img}>
              <img src={img} alt="Mountains" width={600} height={400} />
            </a>
            <div className="desc">content here</div>
          </div>
          <div className="gallery">
            <a target="_blank" href={img}>
              <img src={img} alt="Mountains" width={600} height={400} />
            </a>
            <div className="desc">content here</div>
          </div>
          <div className="gallery">
            <a target="_blank" href={img}>
              <img src={img} alt="Mountains" width={600} height={400} />
            </a>
            <div className="desc">content here</div>
          </div>
          <div className="gallery">
            <a target="_blank" href={img}>
              <img src={img} alt="Mountains" width={600} height={400} />
            </a>
            <div className="desc">content here</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Browsedesign;
